import { WithTranslation } from 'react-i18next';
import { Nutrients } from '../redux/types';

export enum nutrientRequirementState {
  RED = 0,
  YELLOW = 1,
  GREEN = 2,
}

export function EnergyHelper(nutrientState: nutrientRequirementState, t: Function): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return t('nutrientState.energy.green');
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return t('nutrientState.energy.yellow');
  } if (nutrientState === nutrientRequirementState.RED) {
    return t('nutrientState.energy.red');
  }
  throw Error('Invalid nutrient state');
}

export function ProteinHelper(nutrientState: nutrientRequirementState, t: Function): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return t('nutrientState.protein.green');
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return t('nutrientState.protein.yellow');
  } if (nutrientState === nutrientRequirementState.RED) {
    return t('nutrientState.protein.red');
  }
  throw Error('Invalid nutrient state');
}

export function LysineHelper(nutrientState: nutrientRequirementState, t: Function): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return t('nutrientState.lysine.green');
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return t('nutrientState.lysine.yellow');
  } if (nutrientState === nutrientRequirementState.RED) {
    return t('nutrientState.lysine.red');
  }
  throw Error('Invalid nutrient state');
}

export function CalciumHelper(nutrientState: nutrientRequirementState, t: Function): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return t('nutrientState.calcium.green');
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return t('nutrientState.calcium.yellow');
  } if (nutrientState === nutrientRequirementState.RED) {
    return t('nutrientState.calcium.red');
  }
  throw Error('Invalid nutrient state');
}

export function PhosphorusHelper(nutrientState: nutrientRequirementState, t: Function): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return t('nutrientState.phosphorus.green');
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return t('nutrientState.phosphorus.yellow');
  } if (nutrientState === nutrientRequirementState.RED) {
    return t('nutrientState.phosphorus.red');
  }
  throw Error('Invalid nutrient state');
}

export function SodiumHelper(nutrientState: nutrientRequirementState, t: Function): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return t('nutrientState.sodium.green');
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return t('nutrientState.sodium.yellow');
  } if (nutrientState === nutrientRequirementState.RED) {
    return t('nutrientState.sodium.red');
  }
  throw Error('Invalid nutrient state');
}

export function ChlorideHelper(nutrientState: nutrientRequirementState, t: Function): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return t('nutrientState.chloride.green');
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return t('nutrientState.chloride.yellow');
  } if (nutrientState === nutrientRequirementState.RED) {
    return t('nutrientState.chloride.red');
  }
  throw Error('Invalid nutrient state');
}

export function PotassiumHelper(nutrientState: nutrientRequirementState, t: Function): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return t('nutrientState.potassium.green');
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return t('nutrientState.potassium.yellow');
  } if (nutrientState === nutrientRequirementState.RED) {
    return t('nutrientState.potassium.red');
  }
  throw Error('Invalid nutrient state');
}

export function MagnesiumHelper(nutrientState: nutrientRequirementState, t: Function): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return t('nutrientState.magnesium.green');
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return t('nutrientState.magnesium.yellow');
  } if (nutrientState === nutrientRequirementState.RED) {
    return t('nutrientState.magnesium.red');
  }
  throw Error('Invalid nutrient state');
}

export function IronHelper(nutrientState: nutrientRequirementState, t: Function): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return t('nutrientState.iron.green');
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return t('nutrientState.iron.yellow');
  } if (nutrientState === nutrientRequirementState.RED) {
    return t('nutrientState.iron.red');
  }
  throw Error('Invalid nutrient state');
}

export function IodineHelper(nutrientState: nutrientRequirementState, t: Function): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return t('nutrientState.iodine.green');
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return t('nutrientState.iodine.yellow');
  } if (nutrientState === nutrientRequirementState.RED) {
    return t('nutrientState.iodine.red');
  }
  throw Error('Invalid nutrient state');
}

export function CobaltHelper(nutrientState: nutrientRequirementState, t: Function): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return t('nutrientState.cobalt.green');
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return t('nutrientState.cobalt.yellow');
  } if (nutrientState === nutrientRequirementState.RED) {
    return t('nutrientState.cobalt.red');
  }
  throw Error('Invalid nutrient state');
}

export function CopperHelper(nutrientState: nutrientRequirementState, t: Function): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return t('nutrientState.copper.green');
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return t('nutrientState.copper.yellow');
  } if (nutrientState === nutrientRequirementState.RED) {
    return t('nutrientState.copper.red');
  }
  throw Error('Invalid nutrient state');
}

export function ManganeseHelper(nutrientState: nutrientRequirementState, t: Function): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return t('nutrientState.manganese.green');
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return t('nutrientState.manganese.yellow');
  } if (nutrientState === nutrientRequirementState.RED) {
    return t('nutrientState.manganese.red');
  }
  throw Error('Invalid nutrient state');
}

export function ZincHelper(nutrientState: nutrientRequirementState, t: Function): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return t('nutrientState.zinc.green');
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return t('nutrientState.zinc.yellow');
  } if (nutrientState === nutrientRequirementState.RED) {
    return t('nutrientState.zinc.red');
  }
  throw Error('Invalid nutrient state');
}

export function SeleniumHelper(nutrientState: nutrientRequirementState, t: Function): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return t('nutrientState.selenium.green');
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return t('nutrientState.selenium.yellow');
  } if (nutrientState === nutrientRequirementState.RED) {
    return t('nutrientState.selenium.red');
  }
  throw Error('Invalid nutrient state');
}

export function VitAHelper(nutrientState: nutrientRequirementState, t: Function): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return t('nutrientState.vitA.green');
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return t('nutrientState.vitA.yellow');
  } if (nutrientState === nutrientRequirementState.RED) {
    return t('nutrientState.vitA.red');
  }
  throw Error('Invalid nutrient state');
}

export function VitDHelper(nutrientState: nutrientRequirementState, t: Function): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return t('nutrientState.vitD.green');
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return t('nutrientState.vitD.yellow');
  } if (nutrientState === nutrientRequirementState.RED) {
    return t('nutrientState.vitD.red');
  }
  throw Error('Invalid nutrient state');
}

export function VitEHelper(nutrientState: nutrientRequirementState, t: Function): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return t('nutrientState.vitE.green');
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return t('nutrientState.vitE.yellow');
  } if (nutrientState === nutrientRequirementState.RED) {
    return t('nutrientState.vitE.red');
  }
  throw Error('Invalid nutrient state');
}

export function chartTextHelpers(nutrientName: keyof Nutrients) {
  switch (nutrientName) {
  case 'energy':
    return EnergyHelper;
  case 'crudeProtein':
    return ProteinHelper;
  case 'lysine':
    return LysineHelper;
  case 'calcium':
    return CalciumHelper;
  case 'phosphorous':
    return PhosphorusHelper;
  case 'sodium':
    return SodiumHelper;
  case 'chloride':
    return ChlorideHelper;
  case 'potassium':
    return PotassiumHelper;
  case 'magnesium':
    return MagnesiumHelper;
  case 'iron':
    return IronHelper;
  case 'iodine':
    return IodineHelper;
  case 'cobalt':
    return CobaltHelper;
  case 'copper':
    return CopperHelper;
  case 'manganese':
    return ManganeseHelper;
  case 'zinc':
    return ZincHelper;
  case 'selenium':
    return SeleniumHelper;
  case 'vitA':
    return VitAHelper;
  case 'vitD':
    return VitDHelper;
  case 'vitE':
    return VitEHelper;
  default: {
    return (_: nutrientRequirementState) => 'ERROR: No text helper available (chartTextHelpers.ts)';
  }
  }
}
