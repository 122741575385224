/* eslint-disable max-len */
import {
  Feeds, FeedsAction, SET_FEED_CATEGORIES, SET_FEED_BRANDS, SET_FEEDS, ADD_FEED, Feed, ADD_FEED_BRAND, DELETE_FEED,
} from './types';

const defaultState: Feeds = {
  categories: new Map(),
  brands: [],
  feeds: new Map(),
};

const feedsReducer = (state: Feeds = defaultState, action: FeedsAction) => {
  switch (action.type) {
  case (SET_FEED_CATEGORIES): {
    return { ...state, categories: action.payload };
  }
  case (SET_FEED_BRANDS): {
    return { ...state, brands: action.payload };
  }
  case (ADD_FEED_BRAND): {
    const brands = [...state.brands];
    brands.push(action.payload);
    return { ...state, brands };
  }
  case (SET_FEEDS): {
    return { ...state, feeds: action.payload };
  }
  case (ADD_FEED): {
    const newFeeds: Map<string, Feed> = Object.assign(state.feeds).set(action.payload[0], action.payload[1]);
    return { ...state, feeds: newFeeds };
  }
  case (DELETE_FEED): {
    const newFeeds: Map<string, Feed> = Object.assign(state.feeds).delete(action.payload[0]);
    return { ...state, feeds: newFeeds };
  }
  default: {
    return { ...state };
  }
  }
};

export default feedsReducer;
