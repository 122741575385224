import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import {
  Grid, CardContent, CardActions, Button,
} from '@material-ui/core';
import { accountType } from '../redux/types';
import EquineAppConfig from '../EquineAppConfig';

interface Props {
  name: string
  email: string,
  phoneNumber: string,
  role: string,
  creationDate: Date,
  accountType: accountType
  clientCount: number
  onModifyClick: Function
}

const Profile : React.FC<Props> = (props: Props) => (
  <Card style={{ width: '650px', marginTop: '2em' }}>
    <div style={{ textAlign: 'center' }}>
      <img style={{ marginTop: '5%' }} src={EquineAppConfig.branding.logo} alt="brand logo" />
    </div>
    <CardHeader
      title={`${props.name}`}
      subheader={props.role}
    />
    <CardContent>
      <Grid container>
        <Grid item xs={1}>
          <i className="fa fa-envelope" />
        </Grid>
        <Grid item xs={11}>
          {props.email}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={1}>
          <i className="fa fa-phone" />
        </Grid>
        <Grid item xs={11}>
          {props.phoneNumber}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={1}>
          <i className="fa fa-globe" />
        </Grid>
        <Grid item xs={11}>
          {accountType[props.accountType]}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={1}>
          <i className="fa fa-gift" />
        </Grid>
        <Grid item xs={11}>
          Account created on
          &nbsp;
          {props.creationDate.toLocaleDateString()}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={1}>
          <i className="fa fa-address-book" />
        </Grid>
        <Grid item xs={11}>

          {props.clientCount}
          &nbsp;
          Client(s)
        </Grid>
      </Grid>
    </CardContent>
    <CardActions>
      <Button size="small" color="secondary" onClick={() => props.onModifyClick()}>Modify</Button>
    </CardActions>
  </Card>
);

export default Profile;
