/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import firebase from 'firebase/app';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { RootState } from '../../../redux/root';
import {
  NIRNutrients, SET_FEEDS, ADD_FEED, Feed, FeedsAction,
} from '../../../redux/types';

const addNIRNutrients = ([NdfValue, AdfValue, AshValue]: number[], feed: Feed, dispatch: Function, callback: Function) => {
  const nirNutrients: NIRNutrients = {
    NDF: {
      displayName: 'NDF',
      value: NdfValue,
      unit: '%',
    },
    ADF: {
      displayName: 'ADF',
      value: AdfValue,
      unit: '%',
    },
    Ash: {
      displayName: 'Ash',
      value: AshValue,
      unit: '%',
    },
  };
  const ref: firebase.firestore.DocumentReference = firebase.firestore().collection('feeds').doc('roughage').collection('groups')
    .doc(feed.brandId)
    .collection('feeds')
    .doc(feed.id);
  ref.set({ nirNutrients }, { merge: true });
  feed.nirNutrients = nirNutrients;
  const action: FeedsAction = {
    type: ADD_FEED,
    payload: [feed.id, feed],
  };
  dispatch(action);
  callback();
};

interface Props {
  setOpen: Function
  isOpen: boolean
  feed: Feed
}

const handleNutrientValueChange = (e: any) => {
  const start = e.target.selectionStart;
  let val = e.target.value;
  val = val.replace(/([^0-9.]+)/, '');
  val = val.replace(/^(0|\.)/, '');
  const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
  let v = '0';
  if (match) v = match[1] + match[2];
  if (val.length > 0) {
    e.target.value = Number(v).toFixed(2);
    e.target.setSelectionRange(start, start);
  }
  let value: number = Number(v);
  if (value < 0 || value > 500000 || isNaN(value)) {
    value = 0;
  }
  return value;
};

const FeedNIRDialog: React.FC<Props> = (props: Props) => {
  const [NDF, setNDF] = useState(props.feed.nirNutrients ? props.feed.nirNutrients.NDF.value : 0);
  const [ADF, setADF] = useState(props.feed.nirNutrients ? props.feed.nirNutrients.ADF.value : 0);
  const [Ash, setAsh] = useState(props.feed.nirNutrients ? props.feed.nirNutrients.Ash.value : 0);
  const dispatch = useDispatch();
  return (
    <Dialog open={props.isOpen} onClose={() => props.setOpen(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">NIR Report Values</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Add the required nutrient values to the feed for a NIR report.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="NDF"
          label="NDF Value"
          type="text"
          fullWidth
          value={NDF.toFixed(2)}
          onChange={(e) => setNDF(handleNutrientValueChange(e))}
        />
        <TextField
          margin="dense"
          id="ADF"
          label="ADF Value"
          type="text"
          fullWidth
          value={ADF.toFixed(2)}
          onChange={(e) => setADF(handleNutrientValueChange(e))}
        />
        <TextField
          margin="dense"
          id="Ash"
          label="Ash Value"
          type="text"
          fullWidth
          value={Ash.toFixed(2)}
          onChange={(e) => setAsh(handleNutrientValueChange(e))}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setOpen(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={() => addNIRNutrients([NDF, ADF, Ash], props.feed, dispatch, () => props.setOpen(false))} color="primary">
          Save Values
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedNIRDialog;
