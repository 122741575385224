/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// material Ui Stuffs
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Grid, Fab, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import { Link as RouterLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Client, ForageReport } from '../../../redux/types';
import { RootState } from '../../../redux/root';
import getDateAsString from '../../../helpers/dateHelper';

const useStyles = makeStyles((theme) => ({
  '@global': {
    i: {
      fontSize: '2em',
    },
  },
  table: {
    margin: 'auto',
    maxWidth: '50em',
  },
  button: {
    margin: theme.spacing(1),
  },
  grid: {
    margin: 'auto',
    display: 'flex',
    alignContent: 'center',
    maxWidth: '50em',
  },
  fab: {
    margin: theme.spacing(2),
  },
  root: {
    margin: theme.spacing(2),
    marginTop: '3em',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
}));

function displayForageReports(forageReports: ForageReport[]) {
  const result: JSX.Element[] = [];
  forageReports.map((report: ForageReport) => {
    result.push(
      <TableRow key={report.name}>
        <TableCell>{report.name}</TableCell>
        <TableCell>{getDateAsString(report.lastUpdated)}</TableCell>
        <TableCell>
          <IconButton component={RouterLink} to={`/ForageReport/${report.name}`} size="small" color="secondary">
            <KeyboardArrowRightRoundedIcon />
          </IconButton>
        </TableCell>
      </TableRow>,
    );
    return undefined;
  });
  return result;
}

function displayFilter(filter: string, setFilter: Function) {
  return (
    <TextField
      variant="outlined"
      fullWidth
      margin="normal"
      id="filter"
      label="Filter"
      name="filter"
      autoComplete="filter"
      autoFocus
      value={filter}
      onChange={(e) => setFilter(e.target.value)}
    />
  );
}

const ForageReportsNonTranslated : React.FC = (props: any) => {
  const [filter, setFilter] = useState('');
  const name: string = useSelector((state: RootState) => state.user.name);
  let forageReportsLis: ForageReport[] = useSelector((state: RootState) => Array.from(state.forageReports.forageReports.values()));
  const classes = useStyles();
  if (filter) {
    // eslint-disable-next-line max-len
    forageReportsLis = forageReportsLis.filter((client) => client.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
  }
  forageReportsLis.sort((a, b) => b.lastUpdated.getTime() - a.lastUpdated.getTime());
  const { t } = props;
  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h3" className={classes.grid}>
        {t('clients.title', { name })}
      </Typography>
      <Grid container className={classes.grid}>
        <Grid item xs={10}>
          {displayFilter(filter, setFilter)}
        </Grid>
      </Grid>
      <Grid container className={classes.grid}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('table.name')}</TableCell>
                  <TableCell>{t('table.lastUpdated')}</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayForageReports(forageReportsLis)}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>

  );
};

const ForageReports = withTranslation('common')(ForageReportsNonTranslated);

export default ForageReports;
