import React from 'react';
import { withTranslation } from 'react-i18next';

import {
  Nutrients, Horse,
} from '../../redux/types';
import { table, theadStyle, tbodyp } from './horseReportStyle';

interface Props {
  contributors: Nutrients
  horse: Horse
  t: Function
}

const calculateStarchEvaluation = (netContributions: Nutrients, horse: Horse, t: Function) => {
  const starchValue: number = Math.round((netContributions.starch.value / horse.weight) * 1e2) / 1e2;
  if (starchValue > 6) {
    return t('macronutrients.starch.veryHigh', { starchValue });
  } if (starchValue > 4) {
    return t('macronutrients.starch.high', { starchValue });
  } if (starchValue > 2) {
    return t('macronutrients.starch.moderate', { starchValue });
  }
  return t('macronutrients.starch.low', { starchValue });
};

const HorseReportStarchInfo : React.FC<Props> = (props: Props) => (
  <div>
    <p style={tbodyp}>
      Starch is efficiently digested in horse’s small intestine when fed in small amounts per meal. Feeding large amounts of
      starch in each meal can cause undigested starch to enter the hindgut. Health problems can arise when the large
      intestine is overloaded with undigested starch and could result in colic or laminitis. Feeding smaller grain meals more
      frequently can help reduce this risk.
    </p>
    <p style={tbodyp}>
      Consider offering horses with high energy requirements several small meals throughout the day.
    </p>
    <p style={tbodyp}>
      Starch in the diet should be limited if you are managing an overly excitable horse or a horse prone to conditions
      including gastric ulcers, tying up, Cushing’s disease, Equine Metabolic Syndrome.
    </p>
    <p style={tbodyp}>
      {calculateStarchEvaluation(props.contributors, props.horse, props.t)}
    </p>
  </div>
);

const UntranslatedHorseReportStarchInfoSmall : React.FC<Props> = (props: Props) => (
  <p>
    <b>
      {props.t('macronutrients.starch.starch')}
      :
    </b>
    {' '}
    {props.t('macronutrients.starch.bonus')}
    {calculateStarchEvaluation(props.contributors, props.horse, props.t)}
    {' '}
    {props.t('macronutrients.starch.after')}
  </p>
);

export const HorseReportStarchInfoSmall = withTranslation('common')(UntranslatedHorseReportStarchInfoSmall);

export default HorseReportStarchInfo;
