import React from 'react';
import { withTranslation } from 'react-i18next';
import { Nutrients } from '../../redux/types';
import { reportSectionTitle, reportSection } from './horseReportStyle';

interface Props {
  nutrients: (keyof Nutrients)[]
  combinedNetNutrients: Nutrients
  t: Function
}

const getTextFor = (n: keyof Nutrients, t: Function) => {
  switch (n) {
  case 'vitK':
    return (
      <p key={n}>
        <b>
          {t('nutrients.vitK')}
          :
          {' '}
        </b>
        {t('vitaminSupplements.vitK')}
      </p>
    );
  case 'vitC':
    return (
      <p key={n}>
        <b>
          {t('nutrients.vitC')}
          :
          {' '}
        </b>
        {t('vitaminSupplements.vitC')}
      </p>
    );
  case 'biotin':
    return (
      <p key={n}>
        <b>
          {t('nutrients.biotin')}
          :
          {' '}
        </b>
        {t('vitaminSupplements.biotin')}
      </p>
    );
  default:
    return (
      <p key={n}>
        ERROR
        {n}
      </p>
    );
  }
};

const displayComments = (nutrisToComment: (keyof Nutrients)[], combinedNetNutrients: Nutrients, t: Function) => {
  const result: any = [];
  nutrisToComment.forEach((k) => {
    if (combinedNetNutrients[k].value > 0) {
      result.push(getTextFor(k, t));
    }
  });
  return result;
};

const UntranslatedHorsePresenceComments : React.FC<Props> = (props: Props) => {
  const comments: any = displayComments(props.nutrients, props.combinedNetNutrients, props.t);
  if (comments.length > 0) {
    return (
      <div style={{ wordBreak: 'break-word' }}>
        {comments}
      </div>
    );
  }
  return (
    <></>
  );
};

const HorsePresenceComments = withTranslation('common')(UntranslatedHorsePresenceComments);

export default HorsePresenceComments;
