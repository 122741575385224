/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import firebase from 'firebase/app';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Grid, Input, Paper, Typography, Link, Button,
} from '@material-ui/core';
import {
  Feed, ADD_FEED, addFeed, Nutrients, deleteFeed, DELETE_FEED, Nutrient,
} from '../redux/types';
import { RootState } from '../redux/root';
import LoadingBackdrop from './LoadingBackdrop';
import {
  useStyles, handleFeedCSV, AddFeedNutrients, convertNutrientsToBalancedArrays, AddFeedCore,
} from './AddFeed';
import {
  setAddFeedValue, setAddFeedNutrient, SET_ADD_FEED_VALUE, SET_ADD_FEED_NUTRIENT, toggleAddFeedDM, TOGGLE_ADD_FEED_DM,
} from '../redux/addFeed';
import MoveFeedDialog from './MoveFeedDialog';
import DeleteFeedDialog from './DeleteFeedDialog';

interface EditFeedProps {
  categoryId: string
  brandId: string
  feedId: string
}

const updateRedux = (feed: Feed, dispatch: Function, setRedirectTo: Function) => {
  const action: addFeed = {
    type: ADD_FEED,
    payload: [feed.id, feed],
  };
  dispatch(action);
  setRedirectTo(`/feed/c/${feed.categoryId}/b/${feed.brandId}/f/${feed.id}`);
};

const saveChangesToFirebase = (setRedirectTo: Function, isPerKgFeed: boolean, editFeedState: Feed, feed: Feed, brand: string, category: string, dispatch: Function) => {
  const updatedFeed = feed as any;
  updatedFeed.nutrients = editFeedState.nutrients;
  updatedFeed.name = editFeedState.name;
  updatedFeed.DM = editFeedState.dm;
  if (!isPerKgFeed) {
    // convert to per KG feed
    const fixedPlaces: number = 2;
    Object.keys(updatedFeed.nutrients).forEach((key: string) => {
      (updatedFeed.nutrients[key as keyof Nutrients] as Nutrient).value = Number(((updatedFeed.nutrients[key as keyof Nutrients] as Nutrient).value * (feed.dm / 100)).toFixed(fixedPlaces));
    });
  }
  updatedFeed.lastUpdated = firebase.firestore.FieldValue.serverTimestamp();
  const ref: firebase.firestore.DocumentReference = firebase.firestore().collection('feeds').doc(category).collection('groups')
    .doc(brand)
    .collection('feeds')
    .doc(feed.id);
  ref.set(updatedFeed);
  updatedFeed.lastUpdated = new Date();
  updateRedux(updatedFeed as Feed, dispatch, setRedirectTo);
};

const updateAddFeed = (feed: Feed, dispatch: Function) => {
  const action: setAddFeedValue = {
    type: SET_ADD_FEED_VALUE,
    payload: ['id', feed.id],
  };
  dispatch(action);
  Object.keys(feed.nutrients).forEach((key) => {
    const nutrientAction: setAddFeedNutrient = {
      type: SET_ADD_FEED_NUTRIENT,
      payload: [key as keyof Nutrients, feed.nutrients[key as keyof Nutrients].value],
    };
    dispatch(nutrientAction);
  });
  const nameAction: setAddFeedValue = {
    type: SET_ADD_FEED_VALUE,
    payload: ['name', feed.name],
  };
  dispatch(nameAction);
  const dmAction: setAddFeedValue = {
    type: SET_ADD_FEED_VALUE,
    payload: ['dm', feed.dm],
  };
  dispatch(dmAction);
};

const removeFeed = (feed: Feed, setDeleteFeedDialogOpen: Function, dispatch: Function, setRedirectTo: Function) => {
  feed.ref.delete().then(() => {
    const deleteFeedAction: deleteFeed = {
      type: DELETE_FEED,
      payload: [feed.id],
    };
    dispatch(deleteFeedAction);
    setDeleteFeedDialogOpen(false);
    setRedirectTo('/Feeds');
  });
};

const moveFeed = (feed: Feed, selectorState: [string, string], setMoveFeedDialogOpen: Function, dispatch: Function) => {
  const deletedID = `${feed.id}`;
  delete feed.brandId;
  delete feed.categoryId;
  delete (feed as any).id;
  firebase.firestore().collection('feeds').doc(selectorState[0]).collection('groups')
    .doc(selectorState[1])
    .collection('feeds')
    .add(feed)
    .then((ref) => {
    // update redux store
      feed.ref = ref;
      feed.id = ref.id;
      const action: addFeed = {
        type: ADD_FEED,
        payload: [feed.id, feed],
      };
      dispatch(action);
      // delete old entry;
      feed.ref.delete().then(() => {
        // remove old feed from redux store
        const deleteFeedAction: deleteFeed = {
          type: DELETE_FEED,
          payload: [deletedID],
        };
        dispatch(deleteFeedAction);
        setMoveFeedDialogOpen(false);
      });
    });
};

const EditFeed : React.FC = () => {
  const props = useParams();
  const dispatch = useDispatch();
  const feed = useSelector((state: RootState) => state.feeds.feeds.get(props.feedId as string));
  const addFeedState = useSelector((state: RootState) => state.addFeed);
  const [redirectTo, setRedirectTo] = useState('');
  const [isPerKgFeed, setIsPerKgFeed] = useState(true);
  const [attemptedGet, setAttemptedGet] = useState(false);
  const [moveFeedDialogOpen, setMoveFeedDialogOpen] = useState(false);
  const [deleteFeedDialogOpen, setDeleteFeedDialogOpen] = useState(false);
  const user = useSelector((state: RootState) => state.user);
  const perKgFedChangeHandler = (bool: boolean) => {
    setIsPerKgFeed(bool);
    const action: toggleAddFeedDM = {
      type: TOGGLE_ADD_FEED_DM,
      payload: bool,
    };
    dispatch(action);
  };
  const classes = useStyles();
  if (addFeedState.id === '' && feed) {
    updateAddFeed(feed, dispatch);
  }
  const [col1, col2, col3] = convertNutrientsToBalancedArrays(useSelector((state: RootState) => state.addFeed.nutrients));
  if (!feed && !attemptedGet) {
    firebase.firestore().collection('feeds').doc(props.categoryId).collection('groups')
      .doc(props.brandId)
      .collection('feeds')
      .doc(props.feedId)
      .get()
      .then((feedDoc) => {
        if (!feedDoc.exists) return setAttemptedGet(true);
        const feedData: any = feedDoc.data();
        feedData.lastUpdated = feedData.lastUpdated.toDate();
        feedData.creationDate = feedData.creationDate.toDate();
        feedData.categoryId = props.categoryId;
        feedData.brandId = props.brandId;
        feedData.id = feedDoc.id;
        feedData.ref = feedDoc.ref;
        const newFeed: Feed = feedData as Feed;
        const action: addFeed = {
          type: ADD_FEED,
          payload: [newFeed.id, newFeed],
        };
        dispatch(action);
      });
    return (
      <LoadingBackdrop />
    );
  }
  const navigate = useNavigate();
  if (redirectTo) {
    navigate(redirectTo);
  }
  if (attemptedGet) {
    return <p>This feed appears to be lost in the manure...</p>;
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2} direction="column" style={{ textAlign: 'center' }}>
          <Grid item>
            <Typography variant="h2">
              Edit
              &nbsp;
              {feed!.name}
            </Typography>
          </Grid>
          <Grid item>
            <AddFeedCore
              isEditing
              isPerKgFeed={isPerKgFeed}
              setIsPerKgFeed={perKgFedChangeHandler}
              user={user}
            />
          </Grid>
          <Grid item>
            <Button size="large" variant="outlined" onClick={() => saveChangesToFirebase(setRedirectTo, isPerKgFeed, addFeedState, feed!, props.brandId as string, props.categoryId as string, dispatch)}>Save Updated Feed To Cloud</Button>
          </Grid>
          <Grid item container direction="column" spacing={3} style={{ marginBottom: '2em' }}>
            <Grid item>
              <hr style={{ marginTop: '1em' }} />
            </Grid>
            <Grid item>
              <Typography>
                Upload feed values from
                &nbsp;
                <Link target="_blank" href="https://docs.google.com/spreadsheets/d/1LO8veS9NrM0XbFr82OLW4bFE0-36QcIBVEr8ixof4dQ/edit?usp=sharing">official template.</Link>
              </Typography>
            </Grid>
            <Grid item>
              <Input type="file" onChange={(e) => handleFeedCSV(e, dispatch)} />
            </Grid>
            <Grid item>
              <hr style={{ marginTop: '1em' }} />
            </Grid>
            <Grid item>
              <Button onClick={() => setMoveFeedDialogOpen(true)}>
                Move Feed
              </Button>
              <Button onClick={() => setDeleteFeedDialogOpen(true)}>
                Delete Feed
              </Button>
              <MoveFeedDialog isOpen={moveFeedDialogOpen} setOpen={setMoveFeedDialogOpen} callback={(selectorState: [string, string]) => moveFeed(feed!, selectorState, setMoveFeedDialogOpen, dispatch)} />
              <DeleteFeedDialog isOpen={deleteFeedDialogOpen} setOpen={setDeleteFeedDialogOpen} callback={() => removeFeed(feed!, setDeleteFeedDialogOpen, dispatch, setRedirectTo)} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="column">
          <AddFeedNutrients nutrients={col1} />
        </Grid>
        <Grid container direction="column">
          <AddFeedNutrients nutrients={col2} />
        </Grid>
        <Grid container direction="column">
          <AddFeedNutrients nutrients={col3} />
        </Grid>
      </Paper>
    </div>
  );
};

export default EditFeed;
