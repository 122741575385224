/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import firebase from 'firebase/app';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, TextField,
} from '@material-ui/core';
import { RootState } from '../../../redux/root';
import {
  Feed, ForageReportType, ForageReport, ForageReportAction, SET_FORAGE_REPORT,
} from '../../../redux/types';

const generateForageReportDocument = (uid: string, reportName: string, forageReportRadioSelection: ForageReportType, feed: Feed, dispatch: Function, callback: Function) => {
  const forageReport: ForageReport = {
    name: reportName,
    type: forageReportRadioSelection,
    feedID: feed.id,
    creationDate: firebase.firestore.FieldValue.serverTimestamp() as unknown as Date,
    lastUpdated: firebase.firestore.FieldValue.serverTimestamp() as unknown as Date,
  };
  const ref: firebase.firestore.DocumentReference = firebase.firestore()
    .collection('users')
    .doc(uid).collection('forageReports')
    .doc();
  ref.set(forageReport);
  forageReport.creationDate = new Date();
  forageReport.lastUpdated = new Date();
  const setForageReport: ForageReportAction = {
    type: SET_FORAGE_REPORT,
    payload: [ref.id, forageReport],
  };
  dispatch(setForageReport);
  callback();
};

interface Props {
  setOpen: Function
  isOpen: boolean
  feed: Feed
}

const forageReportNames = [
  'NIR - Hay',
  'NIR - Grass',
  'NIR - Haylage',
  'Minerals',
  'Hygiene',
];

interface forageReportRadioGroup {
  value: ForageReportType
  onChange: Function
  feed: Feed
}

const ForageReportRadioGroup: React.FC<forageReportRadioGroup> = (props: forageReportRadioGroup) => (
  <FormControl component="fieldset">
    <FormLabel component="legend">Select Report Type:</FormLabel>
    <RadioGroup
      aria-label="forage report"
      name="forageReportTypes"
      value={props.value}
      onChange={(e) => props.onChange(Number(e.target.value))}
    >
      <FormControlLabel disabled={!props.feed.nirNutrients} value={0} control={<Radio />} label={forageReportNames[0]} />
      <FormControlLabel disabled value={1} control={<Radio />} label={forageReportNames[1]} />
      <FormControlLabel disabled value={2} control={<Radio />} label={forageReportNames[2]} />
      <FormControlLabel disabled value={3} control={<Radio />} label={forageReportNames[3]} />
    </RadioGroup>
  </FormControl>
);

const GenerateForageReportDialog: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const uid = useSelector((state: RootState) => state.user.id);
  const [forageReportRadioSelection, setForageReportRadioSelection] = useState(-1);
  const [reportName, setReportName] = useState(`Forage Report - ${props.feed.name}`);
  return (
    <Dialog open={props.isOpen} onClose={() => props.setOpen(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">NIR Report Values</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Ensure the required values are added for the selected forage report type.
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            id="name"
            label="Report Name"
            type="text"
            value={reportName}
            onChange={(e) => setReportName(e.target.value)}
          />
          <ForageReportRadioGroup feed={props.feed} onChange={setForageReportRadioSelection} value={forageReportRadioSelection} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setOpen(false)} color="secondary">
          Cancel
        </Button>
        <Button
          disabled={forageReportRadioSelection === -1}
          onClick={() => generateForageReportDocument(uid, reportName, forageReportRadioSelection, props.feed, dispatch, () => props.setOpen(false))}
          color="primary"
        >
          Generate Report
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenerateForageReportDialog;
