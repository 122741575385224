/* eslint-disable react/prop-types */
import React from 'react';
import '../styles/Flexbox.scss';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Home from './Home';
import Login from './Login';
import { RootState } from '../redux/root';
import Clients from './Clients';
import { ClientWrapper } from './Client';
import { Client as iClient } from '../redux/types';
import { HorseViewWrapper } from './HorseViewContainer';
import Feeds from './Feeds';
import AddFeed from './AddFeed';
import ViewFeed from './ViewFeed';
import EditFeed from './EditFeed';
import ForgotPassword from './auth/ForgotPassword';
import NewPassword from './auth/NewPassword';
import ForageReports from './features/forageReports/ForageReports';

const Logout = () => {
  const navigate = useNavigate();
  if (firebase.apps.length > 0) firebase.auth().signOut();
  return navigate('/Login');
};

const clientExists = (clients: iClient[], id: string) => {
  if (clients.find((client) => client.id === id)) return true;
  return false;
};

const displayHome = (userLoggedIn: boolean) => (userLoggedIn ? <Home /> : <Login />);

const Main : React.FC = () => {
  const loggedIn: boolean = useSelector((state : RootState) => state.user.loggedIn);
  const clients: iClient[] = useSelector((state : RootState) => state.clients.clients);
  const clientExistsFn = (id: string) => clientExists(clients, id);
  return (
    <Routes>
      <Route path="/" element={displayHome(loggedIn)} />
      <Route path="/Home" element={displayHome(loggedIn)} />
      <Route path="/Login" element={<Login />} />
      <Route path="/Logout" element={Logout} />
      <Route path="/ForgotPassword" element={<ForgotPassword />} />
      <Route path="/NewPassword" element={<NewPassword />} />
      {/* Clients & Horses */}
      <Route path="/Clients" element={<Clients />} />
      <Route
        path="/Client/:client"
        element={<ClientWrapper clientExistsFunction={clientExistsFn} />}
      />
      <Route
        path="/Client/:clientId/Horse/:horseId"
        element={
          <HorseViewWrapper clientExistsFunction={clientExistsFn} />
        }
      />
      {/* Feeds */}
      <Route path="/Feeds" element={<Feeds />} />
      <Route path="/Feeds/Add" element={<AddFeed />} />
      <Route
        path="/Feed/c/:category/b/:brand/f/:feed"
        element={(
          <ViewFeed />
        )}
      />
      <Route
        path="/Feed/c/:categoryId/b/:brandId/f/:feedId/edit"
        element={
          <EditFeed />
        }
      />
      <Route path="/Forage" element={<ForageReports />} />
    </Routes>
  );
};

export default Main;
