import React, { useState } from 'react';
import {
  makeStyles, Table, TableHead, Paper, TableRow, TableCell, Typography, Button, Grid,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import firebase from 'firebase/app';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Feed, addFeed, ADD_FEED, Nutrients,
} from '../redux/types';
import LoadingBackdrop from './LoadingBackdrop';
import { RootState } from '../redux/root';
import FeedNIRDialog from './features/forageReports/FeedNirDialog';
import GenerateForageReportDialog from './features/forageReports/GenerateForageReportDialog';
import { featureIsActive } from '../EquineAppConfig';

const LOCALE: string = 'en-GB';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    marginTop: '1em',
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    flexGrow: 1,
  },
  table: {
    margin: 'auto',
  },
  paper: {
    flexGrow: 1,
    padding: '1em',
    margin: 'auto',
    display: 'flex',
    width: '90vw',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  gridItem: {
    width: '100%',
    textAlign: 'center',
  },
}));

interface Props {
  categoryId: string,
  brandId: string,
  feedId: string,
}

const ViewFeed: React.FC = () => {
  const params = useParams();
  const props: Props = {
    categoryId: params.category as string,
    brandId: params.brand as string,
    feedId: params.feed as string,
  };
  console.log(props, params);
  const classes = useStyles();
  const feeds: Map<string, Feed> = useSelector((state: RootState) => state.feeds.feeds);
  const [attemptedGet, setAttemptedGet] = useState(false);
  /* Feature toggle - Roughage reports */
  const [NIRDialogOpen, setNIRDialogOpen] = useState(false);
  const [forageReportDialogOpen, setForageReportDialogOpen] = useState(false);

  const dispatch = useDispatch();
  if (feeds.get(props.feedId) === undefined && !attemptedGet && !Object.values(props).some((v) => v === undefined)) {
    firebase.firestore().collection('feeds').doc(props.categoryId).collection('groups')
      .doc(props.brandId)
      .collection('feeds')
      .doc(props.feedId)
      .get()
      .then((feedDoc) => {
        if (!feedDoc.exists) return setAttemptedGet(true);
        const feedData: any = feedDoc.data();
        feedData.lastUpdated = feedData.lastUpdated.toDate();
        feedData.creationDate = feedData.creationDate.toDate();
        feedData.categoryId = props.categoryId;
        feedData.brandId = props.brandId;
        feedData.id = feedDoc.id;
        feedData.ref = feedDoc.ref;
        const newFeed: Feed = feedData as Feed;
        const action: addFeed = {
          type: ADD_FEED,
          payload: [feedDoc.id, newFeed],
        };
        dispatch(action);
      });
    return (
      <LoadingBackdrop />
    );
  }
  if (attemptedGet) {
    return <p>This feed appears to be lost in the manure...</p>;
  }
  const feed: Feed = feeds.get(props.feedId)!;
  return (
    <div className={classes.root}>
      <FeedNIRDialog isOpen={NIRDialogOpen} setOpen={setNIRDialogOpen} feed={feed} />
      <GenerateForageReportDialog setOpen={setForageReportDialogOpen} isOpen={forageReportDialogOpen} feed={feed} />
      <div style={{ marginLeft: '6em', marginBottom: '2em' }}>
        <Typography style={{ marginBottom: '.5em' }} variant="h4">{feed.name}</Typography>
        <Typography>
          Last updated on
          {' '}
          {feed.lastUpdated.toLocaleString(LOCALE)}
        </Typography>
        <Typography>
          Created on
          {' '}
          {feed.creationDate.toLocaleString(LOCALE)}
          {' '}
          by
          {' '}
          {feed.ownerName}
        </Typography>
        <Grid style={{ marginTop: '1em' }}>
          <Grid item>
            <Button component={RouterLink} to={`${window.location.pathname}/edit`} variant="outlined" size="large" color="primary">Edit Feed</Button>
          </Grid>
          {featureIsActive('roughageReports')
            ? (
              <>
                <Grid item style={{ marginTop: '1em' }} onClick={() => setNIRDialogOpen(true)}>
                  <Button variant="outlined" size="medium" color="primary">{feed.nirNutrients ? 'Modify NIR Values' : 'Add NIR Values'}</Button>
                </Grid>
                <Grid item style={{ marginTop: '1em' }}>
                  <Button variant="outlined" size="medium" color="primary">Add Mineral Values</Button>
                </Grid>
                <Grid item style={{ marginTop: '1em', marginBottom: '1em' }}>
                  <Button variant="outlined" size="medium" color="primary" onClick={() => setForageReportDialogOpen(true)}>Generate Forage Report</Button>
                </Grid>
              </>
            )
            : ''}
        </Grid>
      </div>
      <Paper className={classes.paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Nutrient
              </TableCell>
              <TableCell>
                Amount (as fed)
              </TableCell>
              <TableCell>
                Unit
              </TableCell>
            </TableRow>
            {Object.keys(feed.nutrients).map((key) => {
              const { nutrients } = feeds.get(props.feedId)!;
              const k = key as keyof Nutrients;
              return (
                <TableRow key={k}>
                  <TableCell>{nutrients[k].displayName}</TableCell>
                  <TableCell>{nutrients[k].value.toFixed(2)}</TableCell>
                  <TableCell>{nutrients[k].unit}</TableCell>
                </TableRow>
              );
            })}
          </TableHead>
        </Table>
      </Paper>
    </div>
  );
};

export default ViewFeed;
