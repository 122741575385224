/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
import {
  HorseType, ADD_HORSE_SET_NAME, AddHorseActions,
  ADD_HORSE_SET_ERROR, ADD_HORSE_SET_TYPE, ADD_HORSE_SET_COMMENT, ADD_HORSE_SET_BREED,
  ADD_HORSE_SET_AGE, ADD_HORSE_SET_INTAKE_LEVEL, ADD_HORSE_SET_WEIGHT,
  ADD_HORSE_SET_SPECIAL, AddHorseSetError, AddHorseSetSpecial, AddHorseSetIntakeLevel,
} from '../redux/types';
import { addHorseInterface } from '../redux/addHorse';

function isBlank(s: string) {
  return s.replace(/^\s+/, '').replace(/\s+$/, '') === '';
}

export function isAddHorseValid(horse: addHorseInterface) {
  if (horse.name.length > 40 || (horse.name.length > 0 && isBlank(horse.name))) return false;
  if (horse.comment.length > 40 || (horse.comment.length > 0 && isBlank(horse.comment))) return false;
  if (horse.breed.length > 40 || (horse.breed.length > 0 && isBlank(horse.breed))) return false;
  if (horse.age > 500 || horse.age < 1) return false;
  if (horse.intakeLevel > 100 || horse.intakeLevel < 0) return false;
  if (horse.weight > 10000 || horse.weight < 1 || isNaN(horse.weight)) return false;
  if (!HorseType[horse.type]) return false;
  if (horse.type === HorseType.Exercise && !horse.special.workLoad) return false;
  if (horse.type === HorseType.Growing && (horse.special.growingWorkLoad === undefined || horse.special.growingWorkLoad < 0)) return false;
  if (horse.type === HorseType.Growing && (horse.special.growingWorkLoad! > 0 && (horse.age < 13))) return false;
  if (horse.type === HorseType.Lactating && (horse.special.lactationMonth === undefined || (horse.special.lactationMonth < 1 || horse.special.lactationMonth > 7))) return false;
  if (horse.type === HorseType.Maintenace && !horse.special.maintenanceLevel) return false;
  if (horse.type === HorseType.Pregnant && (horse.special.gestationMonth === undefined || (horse.special.gestationMonth < 1 || horse.special.gestationMonth > 11))) return false;
  console.log(horse.special.gestationMonth, 'ayayay');
  return true;
}

export function horseNameHandler(horseName: string, dispatch: Function) {
  let action: AddHorseActions;
  if (horseName.length > 40 || (horseName.length > 0 && isBlank(horseName))) {
    // dispatch error
    action = {
      type: ADD_HORSE_SET_ERROR,
      payload: { name: 'Name must be at least 5 characters and at most 30.' },
    };
  } else {
    // dispatch name
    action = {
      type: ADD_HORSE_SET_NAME,
      payload: horseName,
    };
  }
  dispatch(action);
}

export function horseCommentHandler(horseComment: string, dispatch: Function) {
  let action: AddHorseActions;
  if (horseComment.length > 40 || (horseComment.length > 0 && isBlank(horseComment))) {
    // dispatch error
    action = {
      type: ADD_HORSE_SET_ERROR,
      payload: { comment: 'Comment cannot exceed 40 characters.' },
    };
  } else {
    // dispatch name
    action = {
      type: ADD_HORSE_SET_COMMENT,
      payload: horseComment,
    };
  }
  dispatch(action);
}

export function horseBreedHandler(horseBreed: string, dispatch: Function) {
  let action: AddHorseActions;
  if (horseBreed.length > 40 || (horseBreed.length > 0 && isBlank(horseBreed))) {
    // dispatch error
    action = {
      type: ADD_HORSE_SET_ERROR,
      payload: { breed: 'Horse Breed must be between 1 and 40 characters.' },
    };
  } else {
    // dispatch name
    action = {
      type: ADD_HORSE_SET_BREED,
      payload: horseBreed,
    };
  }
  dispatch(action);
}

export function horseAgeHandler(horseAge: number, dispatch: Function) {
  let action: AddHorseActions;
  if (horseAge > 500 || horseAge < 1 || isNaN(horseAge)) {
    // dispatch error
    action = {
      type: ADD_HORSE_SET_ERROR,
      payload: { age: 'Horse Age must be between 1 and 500 (months).' },
    };
    dispatch(action);
    action = {
      type: ADD_HORSE_SET_AGE,
      payload: 0,
    };
  } else {
    // dispatch name
    action = {
      type: ADD_HORSE_SET_AGE,
      payload: horseAge,
    };
  }
  dispatch(action);
}

export function horseIntakeLevelHandler(e: any, dispatch: Function) {
  let action: AddHorseActions;
  const start = e.target.selectionStart;
  let val = e.target.value;
  val = val.replace(/([^0-9.]+)/, '');
  val = val.replace(/^(0|\.)/, '');
  const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
  let v = '0';
  if (match) v = match[1] + match[2];
  if (val.length > 0) {
    e.target.value = Number(v).toFixed(2);
    e.target.setSelectionRange(start, start);
  }
  let intakeLevel: number = Number(v);
  if (isNaN(intakeLevel) || (intakeLevel > 100 || intakeLevel < 0)) {
    intakeLevel = 0;
    // dispatch error
    action = {
      type: ADD_HORSE_SET_ERROR,
      payload: { intakeLevel: 'Intake level must be between 0 and 100.' },
    };
    dispatch(action);
  }
  // dispatch name
  action = {
    type: ADD_HORSE_SET_INTAKE_LEVEL,
    payload: intakeLevel,
  };
  dispatch(action);
}

export function horseWeightHandler(weight: number, dispatch: Function) {
  let action: AddHorseActions;
  if (weight > 10000 || weight < 0 || isNaN(weight)) {
    // dispatch error
    action = {
      type: ADD_HORSE_SET_ERROR,
      payload: { weight: 'Weight must be between 0 and 10000.' },
    };
  } else {
    // dispatch name
    action = {
      type: ADD_HORSE_SET_WEIGHT,
      payload: weight,
    };
  }
  dispatch(action);
}

export function horseTypeHandler(horseType: HorseType, dispatch: Function) {
  let action: AddHorseActions;
  if (HorseType[horseType]) {
    // dispatch horsetype
    action = {
      type: ADD_HORSE_SET_TYPE,
      payload: horseType,
    };
  } else {
    // dispatch error
    action = {
      type: ADD_HORSE_SET_ERROR,
      payload: { type: 'You must set a horse type.' },
    };
  }
  dispatch(action);
}

export function horseSpecialHandler(horseType: HorseType, special: any, dispatch: Function) {
  const specialObject: any = {};
  const action: AddHorseSetSpecial = {
    type: ADD_HORSE_SET_SPECIAL,
    payload: specialObject,
  };
  const error: AddHorseSetError = {
    type: ADD_HORSE_SET_ERROR,
    payload: { special: 'You must fill out the last "special" field.' },
  };
  const setIntakeaction: AddHorseSetIntakeLevel = {
    type: ADD_HORSE_SET_INTAKE_LEVEL,
    payload: 0,
  };
  switch (horseType) {
  case (HorseType.Maintenace): {
    if (special > 0 && special < 4) {
      specialObject.maintenanceLevel = special;
      dispatch(action);
      setIntakeaction.payload = 2;
    } else {
      dispatch(error);
    }
    break;
  }
  case (HorseType.Stallion): {
    if (special === 0 || special === 1) {
      specialObject.stallionMaintenanceLevel = special;
      dispatch(action);
      setIntakeaction.payload = 2;
    } else {
      dispatch(error);
    }
    break;
  }
  //
  case (HorseType.Growing): {
    if (special < 5 && special > -1) {
      specialObject.growingWorkLoad = special;
      dispatch(action);
      setIntakeaction.payload = 2.5;
    } else {
      dispatch(error);
    }
    break;
  }
  case (HorseType.Pregnant): {
    if (special < 12 && special > 0) {
      specialObject.gestationMonth = special;
      dispatch(action);
      setIntakeaction.payload = 2;
    } else {
      error.payload.special = 'Month of gestation must fall between 1 - 11';
      dispatch(error);
      specialObject.gestationMonth = '';
      dispatch(action);
    }
    break;
  }
  case (HorseType.Lactating): {
    if (special < 13 && special > 0) {
      specialObject.lactationMonth = special;
      dispatch(action);
      setIntakeaction.payload = 2.5;
    } else {
      error.payload.special = 'Month of lactation must fall between 1 - 7';
      dispatch(error);
      specialObject.lactationMonth = '';
      dispatch(action);
    }
    break;
  }
  case (HorseType.Exercise): {
    if (special > 0 && special < 5) {
      specialObject.workLoad = special;
      dispatch(action);
      switch (special) {
      case (1): setIntakeaction.payload = 2;
        break;
      case (2): setIntakeaction.payload = 2.25;
        break;
      case (3): setIntakeaction.payload = 2.5;
        break;
      case (4): setIntakeaction.payload = 2.5;
        break;
      default: setIntakeaction.payload = 2.5;
        break;
      }
    } else {
      dispatch(error);
    }
    break;
  }
  default: {
    dispatch(error);
  }
  }
  dispatch(setIntakeaction);
}
