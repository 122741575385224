import DemoFirebaseConfig from './firebaseConfigDemoSystem';
import RedmillsFirebaseConfig from './firebaseConfigRedmillsSystem';

export interface brand {
  id: number
  name: string
  address: string
  headerImage: string
  footerImage: string,
  logo: string
  socialMedia: string
}

export interface FeatureToggles {
  roughageReports: boolean
}

/* Begin Redmills Config */

export const REDMILLS_BRAND: brand = {
  id: 0,
  name: "Connolly's RED MILLS",
  address: 'Goresbridge, County Kilkenny, Ireland',
  headerImage: '/report/redmillsreportheader.png',
  footerImage: '/report/footer_new.png',
  logo: '/report/rm_logo.png',
  socialMedia: '/report/socialmedia.png',
};

export const FORAN_BRAND: brand = {
  id: 1,
  name: 'Foran Equine',
  address: 'Cherry Orchard Industrial Estate, Johnstown, Dublin 10, Ireland',
  headerImage: '/report/foran.jpg',
  footerImage: '/report/footer_new.png',
  logo: '/report/foran_logo.png',
  socialMedia: '/report/foran_socialmedia.png',
};

export const COBRANDING_BRAND: brand = {
  id: 2,
  name: "Connolly's Red Mills & Foran Equine",
  address: '',
  headerImage: '/report/cobranding.png',
  footerImage: '/report/footer_new.png',
  logo: '/report/cobranding.png',
  socialMedia: '/report/foran_socialmedia.png',
};

const redmillsFeatureToggles: FeatureToggles = {
  roughageReports: false,
};

const RedmillsConfig = {
  defaultLocale: 'en',
  firebaseConfig: RedmillsFirebaseConfig,
  featureToggles: redmillsFeatureToggles,
  branding: {
    logo: '/brand.png',
    reports: [
      REDMILLS_BRAND,
      FORAN_BRAND,
      COBRANDING_BRAND,
    ],
  },
};

/* End Redmills Config */

/* Begin Demo Config */

const DemoFeatureToggles: FeatureToggles = {
  roughageReports: true,
};

const DemoConfig = {
  defaultLocale: 'en',
  firebaseConfig: DemoFirebaseConfig,
  featureToggles: DemoFeatureToggles,
  branding: {
    logo: '/demo/brand.png',
    reports: [
      REDMILLS_BRAND,
      FORAN_BRAND,
      COBRANDING_BRAND,
    ],
  },
};

/* End Demo Config */

export const featureIsActive = (toggleName: keyof FeatureToggles): boolean => EquineAppConfig.featureToggles[toggleName] === true;

// The main config to currently use
const EquineAppConfig = RedmillsConfig;
export default EquineAppConfig;
