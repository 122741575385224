import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Paper, Typography, Button, makeStyles, Grid, Card, CardContent, CardActionArea, CardActions,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import {
  Horse, AddHorsePropsFromhorse, ADD_HORSE_PROPS_FROM_HORSE, HorseType,
} from '../redux/types';
import DeleteHorseDialog from './DeleteHorseDialog';
import AddHorseDialog from './AddHorseDialog';
import { monthsToString } from '../helpers/HorseAgeHelper';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    marginTop: '1em',
    padding: '1em',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    flexGrow: 1,
  },
  paper: {
    flexGrow: 1,
    padding: '2em',
    margin: '4em',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0,
      marginTop: '1em',
      width: '100%',
      flexDirection: 'column',
    },
  },
  gridItem: {
    width: '100%',
    textAlign: 'center',
  },
}));

export const extractHorseSpecialInformation = (horse: Horse) => {
  const maintenanceLevels = ['error', 'horse.low', 'horse.average', 'horse.heavy'];
  const stallionLevels = ['horse.breeding', 'horse.notBreeding'];
  const growingLevels = ['horse.none', 'horse.low', 'horse.moderate', 'horse.heavy', 'horse.veryHeavy'];
  const exerciseLevels = ['Error', 'horse.low', 'horse.moderate', 'horse.heavy', 'horse.veryHeavy'];
  switch (horse.type) {
  case HorseType.Maintenace:
    return ['horse.maintenanceLevel', maintenanceLevels[(horse.special as any).maintenanceLevel]];
  case HorseType.Stallion:
    return ['horse.stallionMaintenanceLevel', stallionLevels[(horse.special as any).stallionMaintenanceLevel]];
  case HorseType.Growing:
    return ['horse.growingWorkload', growingLevels[(horse.special as any).growingWorkLoad]];
  case HorseType.Pregnant:
    return ['horse.monthOfGestation', horse.special!.gestationMonth];
  case HorseType.Lactating:
    return ['horse.monthOfLactation', horse.special!.lactationMonth];
  case HorseType.Exercise:
    return ['horse.exerciseWorkload', exerciseLevels[(horse.special as any).workLoad]];
  default:
    return ['ERROR', 'UNKNOWN'];
  }
};

const displayHorseInformation = (horse: Horse, setDeleteHorseDialogOpen: Function, setEditHorseDialogOpen: Function, t: Function) => (
  <Card>
    <CardContent>
      <Typography gutterBottom variant="h5" component="h2" style={{ fontSize: '2rem' }}>
        {t('horse.name')}
        {' '}
        -
        &nbsp;
        {horse.name}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p" style={{ fontSize: '1.2rem' }}>
        <Grid item>
          Comment -
          &nbsp;
          {horse.comment}
        </Grid>
        <Grid item>
          {t('horse.breed')}
          {' '}
          -
          &nbsp;
          {horse.breed}
        </Grid>
        <Grid item>
          {t('horse.age')}
          {' '}
          -
          &nbsp;
          {monthsToString(horse.age)}
        </Grid>
        <Grid item>
          {t('horse.type')}
          {' '}
          -
          &nbsp;
          {HorseType[horse.type]}
        </Grid>
        <Grid item>
          {t('horse.matureAdultWeight')}
          {' '}
          (kg) -
          &nbsp;
          {horse.weight}
        </Grid>
        <Grid item>
          {t('horse.intake level')}
          {' '}
          -
          &nbsp;
          {horse.intakeLevel}
        </Grid>
        <Grid item>
          {t(extractHorseSpecialInformation(horse)[0])}
          &nbsp;-&nbsp;
          {t(extractHorseSpecialInformation(horse)[1])}
        </Grid>
      </Typography>
    </CardContent>
    <CardActionArea>
      <CardActions>
        <Button size="large" color="primary" onClick={() => setEditHorseDialogOpen()}>Edit</Button>
        <Button size="large" color="secondary" onClick={() => setDeleteHorseDialogOpen(true)}>Delete Horse</Button>
      </CardActions>
    </CardActionArea>
  </Card>
);

interface Props {
  horse: Horse
  clientId: string
  t: Function
}

const UntranslatedHorseManageInformation : React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [deleteHorseDialogOpen, setDeleteHorseDialogOpen] = useState(false);
  const [editHorseDialogOpen, setEditHorseDialogOpen] = useState(false);
  const editHorseWrapper = () => {
    const action: AddHorsePropsFromhorse = {
      type: ADD_HORSE_PROPS_FROM_HORSE,
      payload: props.horse,
    };
    dispatch(action);
    setEditHorseDialogOpen(true);
  };
  return (
    <Paper className={classes.root}>
      <Grid container direction="column">
        <Grid item>
          <Typography
            variant="h3"
            align="center"
          >
            Manage Horse Information
          </Typography>
        </Grid>
        {displayHorseInformation(props.horse, setDeleteHorseDialogOpen, editHorseWrapper, props.t)}
      </Grid>
      <AddHorseDialog edit isOpen={editHorseDialogOpen} setOpen={setEditHorseDialogOpen} clientId={props.clientId} horseId={props.horse.id} />
      <DeleteHorseDialog isOpen={deleteHorseDialogOpen} setOpen={setDeleteHorseDialogOpen} clientId={props.clientId} horseId={props.horse.id} />
    </Paper>
  );
};

const HorseManageInformation = withTranslation('common')(UntranslatedHorseManageInformation);

export default HorseManageInformation;
