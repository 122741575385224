import React from 'react';
import { withTranslation } from 'react-i18next';
import { Horse, HorseType } from '../../redux/types';
import { monthsToString } from '../../helpers/HorseAgeHelper';
import { extractHorseSpecialInformation } from '../HorseManageInformation';

interface Props {
  horse: Horse
  t: Function
}

const DietHorseInfoUntranslated: React.FC<Props> = (props: Props) => (
  <div>
    <div>
      <span style={{ fontWeight: 'bold' }}>
        {props.t('horse.name')}
        :
      </span>
      &nbsp;
      {props.horse.name}
    </div>
    <div>
      <span style={{ fontWeight: 'bold' }}>
        {props.t('horse.age')}
        :
      </span>
      &nbsp;
      {monthsToString(props.horse.age, props.t)}
    </div>
    <div>
      <span style={{ fontWeight: 'bold' }}>
        {props.t('horse.breed')}
        :
      </span>
      &nbsp;
      {props.horse.breed}
    </div>
    <div>
      <span style={{ fontWeight: 'bold' }}>
        {props.t('horse.matureAdultWeight')}
        :
      </span>
      &nbsp;
      {props.horse.weight}
      &nbsp;
      kg
    </div>
    <div>
      <span style={{ fontWeight: 'bold' }}>
        {props.t('horse.type')}
        :
      </span>
      &nbsp;
      {HorseType[props.horse.type]}
    </div>
    <div>
      <span style={{ fontWeight: 'bold' }}>
        {props.t(extractHorseSpecialInformation(props.horse)[0])}
        :
      </span>
      &nbsp;
      {props.t(extractHorseSpecialInformation(props.horse)[1])}
    </div>
  </div>
);

const DietHorseInfo = withTranslation('common')(DietHorseInfoUntranslated);

export default DietHorseInfo;
