import React, { useState } from 'react';
import '../styles/Flexbox.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  User, SetEditableAccountOptions, EditableAccountOptions, SET_EDITABLE_ACCOUNT_OPTIONS,
} from '../redux/types';
import { RootState } from '../redux/root';
import Profile from './Profile';
import ModifyProfile from './ModifyProfile';

const Home : React.FC = () => {
  const user: User = useSelector((state: RootState) => state.user);
  const clientCount: number = useSelector((state: RootState) => state.clients.clients.length);
  const dispatch = useDispatch();
  const [modifyProfile, setModifyProfile] = useState(false);
  const onModifyClick = () => {
    const userInfo: EditableAccountOptions = {
      ...user,
    };
    const action: SetEditableAccountOptions = {
      type: SET_EDITABLE_ACCOUNT_OPTIONS,
      payload: userInfo,
    };
    dispatch(action);
    setModifyProfile(true);
  };
  if (modifyProfile) {
    return (
      <div className="Central Row">
        <ModifyProfile goBack={() => setModifyProfile(false)} />
      </div>
    );
  }
  return (
    <div className="Central Row">
      { /* eslint-disable-next-line react/jsx-props-no-spreading */ }
      <Profile {...user} clientCount={clientCount} onModifyClick={() => onModifyClick()} />
    </div>
  );
};

export default Home;
