import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { displayCategorySelector, displayBrandSelector, getSelectors } from './Feeds';
import { RootState } from '../redux/root';
import { Feeds } from '../redux/types';

interface Props {
  setOpen: Function
  isOpen: boolean
  callback: Function
}

const MoveFeedDialog : React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  useEffect(() => getSelectors(dispatch), [dispatch]);
  const [selectorState, setSelectorState] = useState(['-1', '-1']);
  const feeds: Feeds = useSelector((state: RootState) => state.feeds);
  return (
    <Dialog open={props.isOpen} onClose={() => props.setOpen(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Move Feed</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: 'red' }}>
          WARNING: Moving a feed acts as if you delete the feed and create a new one! It will remove the feed from any existing reports!!!
          Select where you wish to move the feed to below.
        </DialogContentText>
        { /* feeds, selectorState as [string, string], setSelectorState) */ }
        <Grid container direction="column">
          <Grid item>
            {displayCategorySelector(feeds, selectorState as [string, string], setSelectorState)}
          </Grid>
          <Grid item>
            {displayBrandSelector(feeds, selectorState as [string, string], setSelectorState)}
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => props.callback(selectorState)}>Confirm</Button>
        <Button color="primary" onClick={() => props.setOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MoveFeedDialog;
