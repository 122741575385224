/* eslint-disable max-len */
import {
  ForageReportAction, ForageReport, SET_FORAGE_REPORT, DELETE_FORAGE_REPORT,
} from './types';

interface ForageReportsState {
  forageReports: Map<string, ForageReport>
}
const defaultState: ForageReportsState = {
  forageReports: new Map(),
};

const forageReportReducer = (state: ForageReportsState = defaultState, action: ForageReportAction) => {
  switch (action.type) {
  case (SET_FORAGE_REPORT): {
    const newReports: Map<string, ForageReport> = Object.assign(state.forageReports).set(action.payload[0], action.payload[1]);
    return { ...state, forageReports: newReports };
  }
  case (DELETE_FORAGE_REPORT): {
    const newReports: Map<string, ForageReport> = Object.assign(state.forageReports);
    newReports.delete(action.payload);
    return { ...state, forageReports: newReports };
  }
  default: {
    return { ...state };
  }
  }
};

export default forageReportReducer;
