import React from 'react';
import {
  Horse, Nutrients, SpecialNutritionalBounds, Nutrient,
} from '../../redux/types';
import { NutrientRequirementsOutput } from '../../helpers/nutrientRequirementWrapper';
import { calculateSpecialRanges } from '../NutritionOverviewGraph';
import '../../styles/Tables.scss';
import { withTranslation } from 'react-i18next';

interface Props {
  combinedNetNutrients: Nutrients
  currentHorseRequirements: NutrientRequirementsOutput
  specialBounds: SpecialNutritionalBounds
  t: Function
}

const tableMappings = [
  ['energy', 'Energy_req'],
  ['crudeProtein', 'CrudeProtein_req'],
  ['lysine', 'Lysine_req'],
  ['calcium', 'Calcium_req'],
  ['phosphorous', 'Phosphorous_req'],
  ['sodium', 'Sodium_req'],
  ['chloride', 'Chloride_req'],
  ['potassium', 'Potassium_req'],
  ['magnesium', 'Magnesium_req'],
  ['iron', 'Iron_req'],
  ['iodine', 'Iodine_req'],
  ['cobalt', 'Cobalt_req'],
  ['copper', 'Copper_req'],
  ['manganese', 'Manganese_req'],
  ['zinc', 'Zinc_req'],
  ['selenium', 'Selenium_req'],
  ['vitA', 'VitaminA_req'],
  ['vitD', 'VitaminD_req'],
  ['vitE', 'VitaminE_req'],
];

const tdStyle = { padding: '2px', borderBottom: 'none', fontSize: '12px' };

const displayComment = (nutrientKey: keyof Nutrients, nutrientPercentage: number, low: Nutrients, high: Nutrients, t: Function) => {
  if (nutrientPercentage < low[nutrientKey].value) {
    return t('low');
  } if (nutrientPercentage >= high[nutrientKey].value) {
    return t('high');
  }
  return t('good');
};

const displayTableBody = (combinedNetNutrients: Nutrients, currentHorseRequirements: NutrientRequirementsOutput, specialBounds: SpecialNutritionalBounds, t: Function) => {
  const result: any = [];
  const [low, high] = calculateSpecialRanges(specialBounds);
  tableMappings.forEach(([nutriKey, reqKey]) => {
    const percentage = (Number(combinedNetNutrients[nutriKey as keyof Nutrients].value) / Number(currentHorseRequirements[reqKey as keyof NutrientRequirementsOutput])) * 100;
    const nutrientName = t(`nutrients.${nutriKey}`);
    result.push(
      <tr key={nutriKey}>
        <td style={tdStyle}>
          {nutrientName}
          {' '}
          {combinedNetNutrients[nutriKey as keyof Nutrients].unit}
        </td>
        <td style={tdStyle}>{currentHorseRequirements[reqKey as keyof NutrientRequirementsOutput].toFixed(2)}</td>
        <td style={tdStyle}>{combinedNetNutrients[nutriKey as keyof Nutrients].value.toFixed(2)}</td>
        <td style={tdStyle}>
          {(percentage).toFixed(0)}
          %
        </td>
        <td style={tdStyle}>{displayComment(nutriKey as keyof Nutrients, percentage, low, high, t)}</td>
      </tr>,
    );
  });
  return result;
};

const UntranslatedDietHorseTable: React.FC<Props> = (props: Props) => (
  <div>
    <table
      style={{
        maxWidth: '500px',
        minWidth: '500px',
        margin: 'auto',
        marginTop: 0,
        padding: '20px',
      }}
      className="dietReportTable"
    >
      <thead>
        <tr style={{ fontWeight: 'bold' }}>
          <td />
          <td>{props.t('nutrientTable.dailyRequirement')}</td>
          <td>{props.t('nutrientTable.dailyIntake')}</td>
          <td>{props.t('nutrientTable.percentage')}</td>
          <td>{props.t('nutrientTable.comment')}</td>
        </tr>
      </thead>
      <tbody>
        {displayTableBody(props.combinedNetNutrients, props.currentHorseRequirements, props.specialBounds, props.t)}
      </tbody>
    </table>
  </div>
);

const DietHorseTable = withTranslation('common')(UntranslatedDietHorseTable);

export default DietHorseTable;
