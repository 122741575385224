import React from 'react';
import { withTranslation } from 'react-i18next';
import { Nutrients } from '../../redux/types';
import calculateDCABValue from '../../helpers/DCABCalculator';
import { reportSection, reportSectionTitle } from './horseReportStyle';
import NutritionRatiosReportGraph from '../NutritionRatiosReportGraph';

interface Props {
  combinedNetNutrients: Nutrients
  feedsAsDM: Nutrients
  totalFeedAmount: number
  totalFeedAmountAsDM: number
  minimalView?: boolean
  t: Function
}

const getCalciumPhosphorusRatio = (calciumValue: number, phosphorusValue: number, t: Function, minimalView?: boolean, small?: boolean) => {
  let ratio: number = calciumValue / phosphorusValue;
  ratio = parseFloat(ratio.toFixed(1));
  let informationalString: string = '';
  if (ratio < 1.5) {
    informationalString = t('ratio.calcphos.low');
    if (small) return [ratio.toFixed(1), t('low'), informationalString];
  } else if (ratio < 6) {
    informationalString = t('ratio.calcphos.good');
    if (small) return [ratio.toFixed(1), t('good'), informationalString];
  } else {
    informationalString = t('ratio.calcphos.high');
    if (small) return [ratio.toFixed(1), t('high'), informationalString];
  }
  return (
    <p key="calcium:phos">
      <b>
        {t('ratio.calcphos.calcium')}
        :
        {' '}
        {t('ratio.calcphos.phosphorus')}
        {' '}
        {t('ratio.ratio')}
        :
        {' '}
      </b>
      {minimalView ? `${ratio.toFixed(1)}` : informationalString}
    </p>
  );
};

const getZincCopperRatio = (ZincValue: number, CopperValue: number, t: Function, minimalView?: boolean, small?: boolean) => {
  let ratio: number = ZincValue / CopperValue;
  ratio = parseFloat(ratio.toFixed(1));
  let informationalString: string = '';
  if (ratio < 3.0) {
    informationalString = t('ratio.zinccopp.low', { ratio });
    if (small) return [ratio, t('low'), informationalString];
  } else if (ratio < 5.0) {
    informationalString = t('ratio.zinccopp.good', { ratio });
    if (small) return [ratio.toFixed(1), t('good'), informationalString];
  } else {
    informationalString = t('ratio.zinccopp.high', { ratio });
    if (small) return [ratio.toFixed(1), t('high'), informationalString];
  }
  return (
    <p key="zinc:copper">
      <b>
        {t('ratio.zinccopp.zinc')}
        :
        {' '}
        {t('ratio.zinccopp.copper')}
        {' '}
        {t('ratio.ratio')}
        :
        {' '}
      </b>
      {minimalView ? `${ratio.toFixed(1)}` : informationalString}
    </p>
  );
};

const getDCAB = (feedsAsDM: Nutrients, totalFeedAmountAsDM: number, t: Function, minimalView?: boolean, small?: boolean) => {
  const DCAB: number = calculateDCABValue(feedsAsDM, totalFeedAmountAsDM) as number;
  const ratio = DCAB.toFixed(0);
  const DCAB_FOR_GRAPH = calculateDCABValue(feedsAsDM, totalFeedAmountAsDM, true);
  let informationalString: string = '';
  if (DCAB > 1 && DCAB < 200) {
    informationalString = t('ratio.dcab.low', { ratio });
    if (small) return [`${DCAB.toFixed(0)}`, t('low'), informationalString, DCAB_FOR_GRAPH];
  } else if (DCAB > 200) {
    informationalString = t('ratio.dcab.good', { ratio });
    if (small) return [`${DCAB.toFixed(0)}`, t('good'), informationalString, DCAB_FOR_GRAPH];
  } else {
    informationalString = t('ratio.dcab.unknownDescription');
    if (small) return ['0', t('unknown'), informationalString, [0, 0, 0, 0, 0, 0, 0]];
  }
  return (
    <p key="dcab">
      <b>
        {t('ratio.dcab.dcab')}
        :
        {' '}
      </b>
      {minimalView ? `${DCAB.toFixed(0)}` : informationalString}
    </p>
  );
};

const getRatios = (combinedNetNutrients: Nutrients, feedsAsDM: Nutrients, totalFeedAmount: number, totalFeedAmountAsDM: number, minimalView: boolean, t: Function) => [
  getDCAB(feedsAsDM, totalFeedAmount, t, minimalView),
  getCalciumPhosphorusRatio(combinedNetNutrients.calcium.value, combinedNetNutrients.phosphorous.value, t, minimalView),
  getZincCopperRatio(combinedNetNutrients.zinc.value, combinedNetNutrients.copper.value, t, minimalView),
];

const UntranslantedHorseDietRatios : React.FC<Props> = (props: Props) => {
  const ratios = getRatios(props.combinedNetNutrients, props.feedsAsDM, props.totalFeedAmount, props.totalFeedAmountAsDM, !!props.minimalView, props.t);
  return (
    <div style={!props.minimalView ? reportSection : { marginTop: '1em', minHeight: 'inherit' }}>
      <div style={reportSectionTitle}>Diet Ratios</div>
      {ratios}
    </div>
  );
};

const UntranslatedHorseDietRatiosSmall : React.FC<Props> = (props: Props) => {
  const [dcabValue, dcabState, dcabInfo, dcabGraphValues]: any = getDCAB(props.combinedNetNutrients, props.totalFeedAmountAsDM, props.t, false, true);
  const [zcValue, zcState, zcInfo]: any = getZincCopperRatio(props.combinedNetNutrients.zinc.value, props.combinedNetNutrients.copper.value, props.t, false, true);
  const [cpValue, cpState, cpInfo]: any = getCalciumPhosphorusRatio(props.combinedNetNutrients.calcium.value, props.combinedNetNutrients.phosphorous.value, props.t, false, true);
  const titleStyle = {
    fontWeight: 800,
    fontSize: '14px',
    marginTop: '1em',
  };
  const stateStyle = {
    fontWeight: 500,
    fontSize: '13px',
    marignTop: '.5em',
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'row', wordBreak: 'break-word' }}>
      <div style={{
        display: 'flex', flexDirection: 'column', margin: 'auto', marginTop: 0, marginRight: '3em', flex: 1,
      }}
      >
        <div style={{ width: '66%', margin: 'auto' }}>
          <NutritionRatiosReportGraph
            contents={dcabGraphValues}
            labels={[]}
            ratioValue={dcabValue}
            backgroundColors={
              [
                '#f1efaa',
                '#eae8b1',
                '#dfdc78',
                '#e3e287',
                '#d4d027',
                '#c3c038',
                '#b5b346',
              ]
            }
          />
        </div>
        <div style={titleStyle}>{props.t('ratio.dcab.dcab')}</div>
        <div style={stateStyle}>
          Result:&nbsp;
          {dcabState}
        </div>
        <div style={{ fontSize: '12px' }}>
          {dcabInfo}
        </div>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        marginTop: 0,
        flex: 1,
      }}
      >
        <div style={{ width: '66%', margin: 'auto' }}>
          <NutritionRatiosReportGraph
            contents={
              [
                props.combinedNetNutrients.calcium.value,
                props.combinedNetNutrients.phosphorous.value,
              ]
            }
            labels={['calcium', 'phosphorous']}
            ratioValue={cpValue}
            backgroundColors={['#8e58b6', '#af91c6']}
          />
        </div>
        <div style={titleStyle}>
          {props.t('ratio.calcphos.calcium')}
          {' '}
          :
          {' '}
          {props.t('ratio.calcphos.phosphorus')}
        </div>
        <div style={stateStyle}>
          Result:&nbsp;
          {cpState}
        </div>
        <div style={{ fontSize: '12px' }}>
          {cpInfo}
        </div>
      </div>
      <div style={{
        display: 'flex', flexDirection: 'column', margin: 'auto', marginTop: 0, marginLeft: '3em', flex: 1,
      }}
      >
        <div style={{ width: '66%', margin: 'auto' }}>
          <NutritionRatiosReportGraph
            contents={[props.combinedNetNutrients.zinc.value, props.combinedNetNutrients.copper.value]}
            ratioValue={zcValue}
            backgroundColors={['#7ce481', '#adecb0']}
            labels={['zinc', 'copper']}
          />
        </div>
        <div style={titleStyle}>
          {props.t('ratio.zinccopp.zinc')}
          {' '}
          :
          {' '}
          {props.t('ratio.zinccopp.copper')}
        </div>
        <div style={stateStyle}>
          Result:&nbsp;
          {zcState}
        </div>
        <div style={{ fontSize: '12px' }}>
          {zcInfo}
        </div>
      </div>
    </div>
  );
};

export const HorseDietRatiosSmall = withTranslation('common')(UntranslatedHorseDietRatiosSmall);

const HorseDietRatios = withTranslation('common')(UntranslantedHorseDietRatios);

export default HorseDietRatios;
