/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles, Paper, Typography, Grid, TextField, TableHead, Table, TableRow, TableCell, TableBody, Button, TableContainer, Tabs, Tab,
} from '@material-ui/core';
import { RootState } from '../redux/root';
import {
  Feeds, AddFeedToCurrentNutritionReport, Feed, ADD_FEED_TO_CURRENT_NUTRITION_REPORT, SetCurrentNutritionReportFeedAmount, SET_CURRENT_NUTRITION_REPORT_AMOUNT, Nutrients, RemoveFeedFromCurrentNutritionReport, REMOVE_FEED_FROM_CURRENT_NUTRITION_REPORT, SpecialNutritionalBounds,
} from '../redux/types';
import {
  displayBrandSelector, displayCategorySelector, getSelectors, getFeeds,
} from './Feeds';
import 'firebase/firestore';
import NutritionOverviewGraph from './NutritionOverviewGraph';
import NutritionContributorsGraph from './NutritionContributorsGraph';
import NutritionContributorsValuesGraph from './NutritionContributorsValuesGraph';
import HorseDietRatios from './viewReport/HorseDietRatios';
import { FeedWithAmount } from '../helpers/horsePercentageManger';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    marginTop: '1em',
    padding: '1em',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    flexGrow: 1,
  },
  table: {
    margin: 'auto',
  },
  paper: {
    flexGrow: 1,
    padding: '1em',
    margin: '1em',
    display: 'flex',
    width: '90vw',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0,
      marginTop: '1em',
      width: '100%',
      flexDirection: 'column',
    },
  },
  gridItem: {
    width: '100%',
    textAlign: 'center',
  },
}));

const AddFeedToReport = (feed: Feed, dispatch: Function) => {
  const action: AddFeedToCurrentNutritionReport = {
    type: ADD_FEED_TO_CURRENT_NUTRITION_REPORT,
    payload: [feed.id, feed.ref],
  };
  dispatch(action);
};

function displayFilter(filter: string, setFilter: Function) {
  return (
    <TextField
      variant="outlined"
      fullWidth
      margin="normal"
      id="filter"
      label="Filter"
      name="filter"
      autoComplete="filter"
      value={filter}
      onChange={(e) => setFilter(e.target.value)}
    />
  );
}

/* Left side panel for adding feeds */
const ManageFeeds : React.FC = () => {
  const [selectorState, setSelectorState] = useState(['-1', '-1']);
  const [filter, setFilter] = useState('');
  const dispatch = useDispatch();
  const feeds: Feeds = useSelector((state: RootState) => state.feeds);
  useEffect(() => getFeeds(feeds, selectorState as [string, string], dispatch), [selectorState[1]]);
  const classes = useStyles();
  let visibleFeeds = Array.from(feeds.feeds.values());
  if (filter) {
    // eslint-disable-next-line max-len
    visibleFeeds = visibleFeeds.filter((feed) => (feed.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1));
  }
  if (selectorState[1] !== '-1') {
    visibleFeeds = visibleFeeds.filter((feed) => feed.categoryId === selectorState[0] && feed.brandId === selectorState[1]);
  } else if (visibleFeeds.length > 20) {
    visibleFeeds = visibleFeeds.slice(0, 20);
  }
  visibleFeeds.sort((a, b) => b.lastUpdated.getTime() - a.lastUpdated.getTime());
  return (
    <Paper className={classes.paper}>
      <Grid container direction="column" spacing={1}>
        <Grid item className={classes.gridItem}>
          <Typography variant="h4">Select Feeds</Typography>
        </Grid>
        <Grid item className={classes.gridItem}>
          {displayCategorySelector(feeds, selectorState as [string, string], setSelectorState)}
        </Grid>
        <Grid item className={classes.gridItem}>
          {displayBrandSelector(feeds, selectorState as [string, string], setSelectorState)}
        </Grid>
        <Grid item className={classes.gridItem}>
          {displayFilter(filter, setFilter)}
        </Grid>
        <Grid item>
          <TableContainer className={classes.table}>
            <Table className={classes.table} aria-label="Add Feeds Table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleFeeds.map((feed: Feed) => (
                  <TableRow key={feed.id}>
                    <TableCell>{feed.name}</TableCell>
                    <TableCell><Button onClick={() => AddFeedToReport(feed, dispatch)}>Add</Button></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  );
};

const setFeedAmount = (feed: Feed, e: any, dispatch: Function) => {
  const start = e.target.selectionStart;
  let val = e.target.value;
  val = val.replace(/([^0-9.]+)/, '');
  val = val.replace(/^(0|\.)/, '');
  const match = /(\d{0,7})[^.]*((?:\.\d{0,3})?)/g.exec(val);
  let value = '0';
  if (match) value = match[1] + match[2];
  if (val.length > 0) {
    e.target.value = Number(value).toFixed(3);
    e.target.setSelectionRange(start, start);
  }
  let newAmount = Number(value);
  newAmount = isNaN(newAmount) || newAmount < 0 || newAmount > 100 ? 0 : newAmount;
  const action: SetCurrentNutritionReportFeedAmount = {
    type: SET_CURRENT_NUTRITION_REPORT_AMOUNT,
    payload: [feed.id, feed.ref, newAmount],
  };
  dispatch(action);
};

const removeFeed = (feedId: string, dispatch: Function) => {
  const action: RemoveFeedFromCurrentNutritionReport = {
    type: REMOVE_FEED_FROM_CURRENT_NUTRITION_REPORT,
    payload: [feedId],
  };
  dispatch(action);
};

const ManageAmounts: React.FC = () => {
  const dispatch = useDispatch();
  const feeds = useSelector((state: RootState) => state.feeds);
  const currentReport = useSelector((state: RootState) => state.currentNutritionReport);
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container direction="column" style={{ textAlign: 'center' }}>
        <Grid item>
          <Typography variant="h4">Manage Feeds</Typography>
        </Grid>
        <Grid item>
          <TableContainer className={classes.table}>
            <Table className={classes.table} aria-label="Add Feeds Table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(currentReport.feedAmounts).map(([key, value]) => {
                  const feed: Feed = feeds.feeds.get(key) as Feed;
                  return (
                    <TableRow key={key}>
                      <TableCell title={feed.ref.path.indexOf('feeds/roughage') !== -1 ? 'This feed contributes to the roughage calculation' : ''} style={feed.ref.path.indexOf('feeds/roughage') !== -1 ? { color: '#ffc000', cursor: 'help' } : {}}>
                        {feed.name as string}
                        {feed.ref.path.indexOf('feeds/roughage') !== -1 ? ` ${feed.dm}% DM` : ''}
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          margin="dense"
                          id={key}
                          label="kg (as fed)"
                          type="text"
                          value={value[1] !== undefined ? value[1].toFixed(3) : -1}
                          onChange={(e) => { setFeedAmount(feed, e, dispatch); }}
                        />
                      </TableCell>
                      <TableCell>
                        <Button key={key} onClick={() => removeFeed(key, dispatch)}>Remove</Button>
                      </TableCell>

                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  );
};

/* Middle panel for managing the amount of the feed to be added */

interface Props {
  percentages: Nutrients | undefined
  combinedNetNutrients: Nutrients
  contributors: [string, Nutrients][] | undefined
  specialBounds: SpecialNutritionalBounds | undefined
  totalFeedAmount: number
  feedsWithAmounts: FeedWithAmount[] | undefined
  feedsAsDM: Nutrients | undefined
  totalFeedAmountAsDM: number
}

const GraphPreviews : React.FC<Props> = (props : Props) => {
  const [activeGraph, setActiveGraph] = useState(0);
  if (props.percentages && props.contributors && props.specialBounds && props.feedsWithAmounts && props.feedsAsDM) {
    return (
      <Grid container direction="column" style={{ textAlign: 'center', margin: '1em' }}>
        <Paper square>
          <Grid item>
            <Tabs
              value={activeGraph}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, number) => setActiveGraph(number)}
              aria-label="graph selector"
            >
              <Tab label="Overview" />
              <Tab label="Contributors" />
              <Tab label="Contributors Values" />
              <Tab label="Ratios" />
            </Tabs>
          </Grid>
          <Grid item>
            {activeGraph === 0 ? <NutritionOverviewGraph height={250} specialBounds={props.specialBounds} percentages={props.percentages} /> : ''}
            {activeGraph === 1 ? <NutritionContributorsGraph height={250} contributors={props.contributors} /> : ''}
            {activeGraph === 2 ? <NutritionContributorsValuesGraph height={250} feedsWithAmounts={props.feedsWithAmounts} /> : ''}
            {activeGraph === 3 ? <HorseDietRatios feedsAsDM={props.feedsAsDM} totalFeedAmountAsDM={props.totalFeedAmountAsDM} totalFeedAmount={props.totalFeedAmount} combinedNetNutrients={props.combinedNetNutrients} minimalView /> : ''}
          </Grid>
        </Paper>
      </Grid>
    );
  }
  return (
    <Grid container direction="column" style={{ textAlign: 'center', margin: '1em' }}>
      <Paper square style={{ margin: 'auto' }}>
        <Typography variant="h5" style={{ margin: '2em' }}>
          At least one feed must be added for graph previews.
        </Typography>
      </Paper>
    </Grid>
  );
};

const HorseManageFeeds : React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  useEffect(() => getSelectors(dispatch), []);
  // get report if exists and dispatch it to currentReport
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ManageFeeds />
        <ManageAmounts />
        <GraphPreviews
          totalFeedAmount={props.totalFeedAmount}
          combinedNetNutrients={props.combinedNetNutrients}
          specialBounds={props.specialBounds}
          contributors={props.contributors}
          percentages={props.percentages}
          feedsWithAmounts={props.feedsWithAmounts}
          feedsAsDM={props.feedsAsDM}
          totalFeedAmountAsDM={props.totalFeedAmountAsDM}
        />
      </Paper>
    </div>
  );
};

export default HorseManageFeeds;
