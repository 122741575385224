/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
import {
  Feed, Nutrients, defaultNutrients, Horse, SpecialNutritionalBounds,
} from '../redux/types';
import { NutrientRequirementsOutput } from './nutrientRequirementWrapper';

export interface FeedWithAmount extends Feed {
  amount: number
}

export const calculateFeedsWithAmount = (feeds: Map<string, Feed>, feedAmounts: [string, [firebase.firestore.DocumentReference, number]][]) => {
  const feedsWithAmount: FeedWithAmount[] = feedAmounts.map(([id, [_, amount]]) => {
    const foundFeed: Feed | undefined = feeds.get(id);
    if (foundFeed === undefined) {
      throw new Error('Invalid Feed!');
    }
    (foundFeed as FeedWithAmount).amount = amount;
    return Object.assign((foundFeed as FeedWithAmount));
  });
  return feedsWithAmount;
};

function feedsToNutrients(feeds: FeedWithAmount[]): Nutrients {
  const reportFeeds: FeedWithAmount[] = feeds;
  if (reportFeeds.length < 1) throw new Error('At least one feed is required to compute nutrients');

  let feed: FeedWithAmount | undefined = reportFeeds.shift()!;
  const combinedNutrients: any = {};
  Object.keys((feed!.nutrients)).map((key) => {
    // eslint-disable-next-line no-return-assign
    return combinedNutrients[key] = {
      displayName: feed!.nutrients[key as keyof Nutrients].displayName,
      unit: feed!.nutrients[key as keyof Nutrients].unit,
      value: 0,
    };
  });
  while (feed !== undefined) {
    // eslint-disable-next-line no-loop-func
    Object.keys(combinedNutrients).forEach((key) => {
      combinedNutrients[key as keyof Nutrients].value += (feed!.nutrients[key as keyof Nutrients].value * feed!.amount);
    });
    feed = reportFeeds.shift();
  }
  return combinedNutrients;
}

export const calculateTotalNutrientsAsDM = (feedsWithAmounts: FeedWithAmount[]) => {
  const combinedNutrientsDM: any = {};
  let totalFeedAmountAsDM: number = 0;
  const feed = feedsWithAmounts[0];
  Object.keys((feed!.nutrients)).map((key) => {
    // eslint-disable-next-line no-return-assign
    return combinedNutrientsDM[key] = {
      displayName: feed!.nutrients[key as keyof Nutrients].displayName,
      unit: feed!.nutrients[key as keyof Nutrients].unit,
      value: 0,
    };
  });
  // Code below assumes that feeds will always be in a standard unit
  feedsWithAmounts.map((fwa: FeedWithAmount) => {
    totalFeedAmountAsDM += (fwa.amount * (fwa.dm / 100));
    Object.keys(fwa.nutrients).map((nutrikey) => combinedNutrientsDM[nutrikey as keyof Nutrients].value = combinedNutrientsDM[nutrikey as keyof Nutrients].value
                                                    + ((fwa.nutrients[nutrikey as keyof Nutrients].value * fwa.amount)
                                                    * (fwa.dm / 100)));
  });
  // Now all feeds should have relevant DM values
  return [combinedNutrientsDM, totalFeedAmountAsDM];
};

/*
 * How the special requirements work
 * Potassium
 * .1 max
 * 25 grams = 100; nrc formula
 * max intake = 1% of what it's eating a day
 * if its eating 10kg a day so the max intake is 100g
 * so dynamic range?
 * 100 / 25 = 400%
 */

function calculateSpecials(horse: Horse, totalFeedToEat: number, nutrientRequirements: NutrientRequirementsOutput): SpecialNutritionalBounds {
  const totalFeedInGrams: number = totalFeedToEat * 1000; // totalFeedToEat is in KG
  const potassiumUpperLimit = (((totalFeedInGrams / 1000) * 20) / nutrientRequirements.Potassium_req) * 100; // 20g
  const calciumUpperLimit = (((totalFeedInGrams / 1000) * 20) / nutrientRequirements.Calcium_req) * 100; // 20g
  const sodiumUpperLimit = (((totalFeedInGrams / 1000) * 24) / nutrientRequirements.Sodium_req) * 100; // 24g
  const chlroideUpperLimit = (((totalFeedInGrams / 1000) * 36) / nutrientRequirements.Chloride_req) * 100; // 36g
  const phosphorousUpperLimit = (((totalFeedInGrams / 1000) * 10) / nutrientRequirements.Phosphorous_req) * 100; // 10g
  const magnesiumUpperLimit = (((totalFeedInGrams / 1000) * 8) / nutrientRequirements.Magnesium_req) * 100; // 8g
  // Requirements below are in mg.
  const copperUpperLimit = ((totalFeedToEat * 290.70) / nutrientRequirements.Copper_req) * 100;
  const ManganeseUpperLimit = ((totalFeedToEat * 400) / nutrientRequirements.Manganese_req) * 100;
  const IronUpperLimit = ((totalFeedToEat * 581.40) / nutrientRequirements.Iron_req) * 100;
  const IodineUpperLimit = ((totalFeedToEat * 5) / nutrientRequirements.Iodine_req) * 100;
  const SeleniumUpperLimit = ((totalFeedToEat * 0.5) / nutrientRequirements.Selenium_req) * 100;
  const ZincUpperLimit = ((totalFeedToEat * 581.40) / nutrientRequirements.Zinc_req) * 100;
  const snb: SpecialNutritionalBounds = {
    upper: {
      calcium: calciumUpperLimit,
      potassium: potassiumUpperLimit,
      sodium: sodiumUpperLimit,
      chloride: chlroideUpperLimit,
      phosphorous: phosphorousUpperLimit,
      magnesium: magnesiumUpperLimit,
      copper: copperUpperLimit,
      manganese: ManganeseUpperLimit,
      iron: IronUpperLimit,
      iodine: IodineUpperLimit,
      selenium: SeleniumUpperLimit,
      zinc: ZincUpperLimit,
    },
  };
  return snb;
}

const calculateRoughage = (feedsWithAmount: FeedWithAmount[]) => {
  let totalRoughageAmount = 0;
  const roughageFeeds = Array.from(feedsWithAmount.values()).filter((feed: Feed) => feed.ref.path.indexOf('feeds/roughage') !== -1);
  roughageFeeds.forEach((feed) => {
    totalRoughageAmount += feed.amount * (feed.dm / 100);
  });
  return totalRoughageAmount;
};

const horsePercentageCalculator = (feeds: Map<string, Feed>, feedAmounts: [string, [firebase.firestore.DocumentReference, number]][], nutrientRequirements: NutrientRequirementsOutput, horse :Horse): [Nutrients, SpecialNutritionalBounds | undefined, Nutrients, Number] => {
  const feedsWithAmount: FeedWithAmount[] = calculateFeedsWithAmount(feeds, feedAmounts);
  if (feedsWithAmount.length === 0) return [Object.assign(defaultNutrients), undefined, Object.assign(defaultNutrients), 0];
  const amounts: number[] = feedsWithAmount.map((fwa) => fwa.amount);
  const specials: SpecialNutritionalBounds = calculateSpecials(horse, amounts.reduce((a, b) => { return a + b; }, 0), nutrientRequirements);
  const roughageAmount = calculateRoughage(feedsWithAmount);
  const givenNutrients = feedsToNutrients(feedsWithAmount);
  const netCombiendNutrients: Nutrients = JSON.parse(JSON.stringify(givenNutrients));
  givenNutrients.biotin.value = (givenNutrients.biotin.value / (2 * horse.weight)) * 100;
  givenNutrients.calcium.value = (givenNutrients.calcium.value / nutrientRequirements.Calcium_req) * 100;
  givenNutrients.chloride.value = (givenNutrients.chloride.value / nutrientRequirements.Chloride_req) * 100;
  givenNutrients.copper.value = (givenNutrients.copper.value / nutrientRequirements.Copper_req) * 100;
  givenNutrients.cobalt.value = (givenNutrients.cobalt.value / nutrientRequirements.Cobalt_req) * 100;
  givenNutrients.crudeProtein.value = (givenNutrients.crudeProtein.value / nutrientRequirements.CrudeProtein_req) * 100;
  givenNutrients.energy.value = (givenNutrients.energy.value / (nutrientRequirements.Energy_req)) * 100;
  // givenNutrients.fat.value = 0;
  // givenNutrients.fibre.value = 0;
  givenNutrients.iodine.value = (givenNutrients.iodine.value / nutrientRequirements.Iodine_req) * 100;
  givenNutrients.iron.value = (givenNutrients.iron.value / nutrientRequirements.Iron_req) * 100;
  givenNutrients.lysine.value = (givenNutrients.lysine.value / nutrientRequirements.Lysine_req) * 100;
  givenNutrients.magnesium.value = (givenNutrients.magnesium.value / nutrientRequirements.Magnesium_req) * 100;
  givenNutrients.manganese.value = (givenNutrients.manganese.value / nutrientRequirements.Manganese_req) * 100;
  givenNutrients.phosphorous.value = (givenNutrients.phosphorous.value / nutrientRequirements.Phosphorous_req) * 100;
  givenNutrients.potassium.value = (givenNutrients.potassium.value / nutrientRequirements.Potassium_req) * 100;
  givenNutrients.selenium.value = (givenNutrients.selenium.value / nutrientRequirements.Selenium_req) * 100;
  givenNutrients.sodium.value = (givenNutrients.sodium.value / nutrientRequirements.Sodium_req) * 100;
  // givenNutrients.starch.value = 0;
  // givenNutrients.sugar.value = 0;
  givenNutrients.sulphur.value = (givenNutrients.sulphur.value / nutrientRequirements.Sulphur_req) * 100;
  givenNutrients.vitA.value = (givenNutrients.vitA.value / nutrientRequirements.VitaminA_req) * 100;
  // givenNutrients.vitC.value = 0;
  givenNutrients.vitD.value = (givenNutrients.vitD.value / nutrientRequirements.VitaminD_req) * 100;
  givenNutrients.vitE.value = (givenNutrients.vitE.value / nutrientRequirements.VitaminE_req) * 100;
  // givenNutrients.vitK.value
  givenNutrients.zinc.value = (givenNutrients.zinc.value / nutrientRequirements.Zinc_req) * 100;
  Object.values(givenNutrients).forEach((nutrient) => nutrient.unit = '%');
  return [givenNutrients, specials, netCombiendNutrients, roughageAmount];
};

export default horsePercentageCalculator;
