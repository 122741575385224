import React from 'react';
import '../styles/Flexbox.scss';
import '../styles/Navbar.scss';
import { useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { RootState } from '../redux/root';
import NavbarItem from './NavbarItem';
import EquineAppConfig, { featureIsActive } from '../EquineAppConfig';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
}));

const Navbar: React.FC = () => {
  const classes = useStyles();
  const loggedIn: boolean = useSelector((state : RootState) => state.user.loggedIn);
  if (loggedIn) {
    return (
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarTitle}>
            <img src={EquineAppConfig.branding.logo} className="Brand" alt="brand logo" />
          </div>
          <nav>
            <NavbarItem name="Home" to="/" />
            <NavbarItem name="Clients" to="/Clients" />
            <NavbarItem name="Feeds" to="/Feeds" />
            {featureIsActive('roughageReports') ? <NavbarItem name="Forage Reports" to="/Forage" /> : ''}
          </nav>
          <Button component={RouterLink} to="/logout" color="primary" variant="outlined" className={classes.link}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    );
  }
  return (
    <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.toolbarTitle}>
          <img src={EquineAppConfig.branding.logo} className="Brand" alt="brand logo" />
        </div>
        <Button component={RouterLink} to="/login" color="primary" variant="outlined" className={classes.link}>
          Login
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
