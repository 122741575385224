const RedmillsFirebaseConfig = {
  apiKey: 'AIzaSyB5osv6Lm_Cpoq7Dym3eEyINFR9Rl19KHE',
  authDomain: 'bellerphon-demo.firebaseapp.com',
  databaseURL: 'https://bellerphon-demo.firebaseio.com',
  projectId: 'bellerphon-demo',
  storageBucket: 'bellerphon-demo.appspot.com',
  messagingSenderId: '656444323630',
  appId: '1:656444323630:web:2a96c19c7007e7b371ecbf',
};

export default RedmillsFirebaseConfig;
