export const monthsToString = (months: number, t?: Function): string => {
  const years = Math.floor(months / 12);
  const modMonths = months % 12;
  let s = '';
  let year_text = 'year';
  let month_text = 'month';
  let years_text = 'years';
  let months_text = 'months';
  let and = '';
  if (t !== undefined) {
    years_text = t('horse.years');
    months_text = t('horse.months');
    year_text = t('horse.year');
    month_text = t('horse.month');
    and = t('horse.and');
  }
  if (years !== 0) {
    if (years === 1) {
      s += `${years.toString()} ${year_text}`;
    } else {
      s += `${years.toString()} ${years_text}`;
    }
    if (modMonths > 0) s += ` ${and} `;
  }
  if (modMonths !== 0) {
    if (modMonths === 1) {
      s += `${modMonths} ${month_text}`;
    } else {
      s += `${modMonths} ${months_text}`;
    }
  }
  return s;
};

export const monthsToYearsMonths = (months:number) : [number, number] => {
  const years = Math.floor(months / 12);
  const modMonths = months % 12;
  return [years, modMonths];
};

export const yearsMonthsToMonths = (years: number, months: number) : number => years * 12 + months;
