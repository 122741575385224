import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';

import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { BrowserRouter } from 'react-router-dom';

import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import firebase from 'firebase/app';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import rootReducer from './redux/root';
import EquineAppConfig from './EquineAppConfig';

import commonEN from './locales/en/common.json';
import redmillsFrench from './locales/fr/redmillsFrench.json';

const firebaseApp: firebase.app.App = firebase.initializeApp(EquineAppConfig.firebaseConfig);
const firestore: firebase.firestore.Firestore = firebaseApp.firestore();
firestore.enablePersistence({ synchronizeTabs: true });

const persistConfig = {
  key: 'root',
  blacklist: ['addHorse', 'editAccount'],
  storage,
};

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'en', // language to use
  resources: {
    en: {
      common: commonEN, // 'common' is our custom namespace
    },
    fr: {
      common: redmillsFrench,
    },
  },
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeWithDevTools());
const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>, document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
