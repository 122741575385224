import React from 'react';
import { withTranslation } from 'react-i18next';

import {
  Nutrients,
} from '../../redux/types';
import { table, theadStyle, tbodyp } from './horseReportStyle';

interface Props {
  contributors: Nutrients
  small?: boolean
  t: Function
}

const calculateSugarPercent = (netContributions: Nutrients) => {
  const otherMacrosSum = netContributions.crudeProtein.value + netContributions.fat.value + netContributions.starch.value + netContributions.sugar.value + netContributions.fibre.value;
  return Math.round((netContributions.sugar.value / otherMacrosSum) * (100 / 1));
};

const UntranslatedHorseReportSugarInfo : React.FC<Props> = (props: Props) => {
  const sugarPercent = calculateSugarPercent(props.contributors);
  return (
    <p style={props.small ? {} : tbodyp}>
      <b>
        {props.t('macronutrients.sugar.sugar')}
        :
      </b>
      {' '}
      {props.t('macronutrients.sugar.description', { sugarPercent })}
    </p>
  );
};

const HorseReportSugarInfo = withTranslation('common')(UntranslatedHorseReportSugarInfo);

export default HorseReportSugarInfo;
