/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import '../styles/Flexbox.scss';
import '../styles/Tables.scss';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { RootState } from '../redux/root';

import {
  AddClientHorse, ADD_CLIENT_HORSE, HorseType, AddHorseError, ADD_HORSE_SET_ERROR, AddHorseSetError,
} from '../redux/types';
import { addHorseInterface } from '../redux/addHorse';
import {
  horseNameHandler, horseTypeHandler, horseWeightHandler, horseSpecialHandler,
  horseIntakeLevelHandler, horseBreedHandler, horseCommentHandler, horseAgeHandler,
  isAddHorseValid,
} from '../helpers/AddHorseHelpers';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '70ch',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '30ch',
  },
}));

function addNewHorse(addHorse: addHorseInterface, clientId: string, uid: string, dispatch: Function, callback: Function) {
  if (!isAddHorseValid(addHorse)) {
    // dispatch an error
    const action: AddHorseSetError = {
      type: ADD_HORSE_SET_ERROR,
      payload: { special: 'Unable to confirm horse information - information supplied is invalid.' },
    };
    dispatch(action);
    return undefined;
  }
  const horse: any = {
    name: addHorse.name,
    breed: addHorse.breed,
    weight: addHorse.weight,
    age: addHorse.age,
    intakeLevel: addHorse.intakeLevel,
    type: addHorse.type,
    comment: addHorse.comment,
    creationDate: firebase.firestore.FieldValue.serverTimestamp(),
    lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
    special: addHorse.special,
  };
  const ref: firebase.firestore.DocumentReference = firebase.firestore().collection('users').doc(uid).collection('clients')
    .doc(clientId)
    .collection('horses')
    .doc();
  ref.set(horse);
  horse.id = ref.id;
  horse.creationDate = new Date();
  horse.lastUpdated = new Date();
  const action: AddClientHorse = {
    type: ADD_CLIENT_HORSE,
    payload: {
      clientId,
      horse,
    },
  };
  dispatch(action);
  callback();
  return undefined;
}

function editHorse(addHorse: addHorseInterface, clientId: string, horseId: string, uid: string, dispatch: Function, callback: Function) {
  if (!isAddHorseValid(addHorse)) {
    // dispatch an error
    const action: AddHorseSetError = {
      type: ADD_HORSE_SET_ERROR,
      payload: { special: 'Unable to change horse information - information supplied is invalid.' },
    };
    dispatch(action);
    return undefined;
  }
  const horse: any = {
    name: addHorse.name,
    breed: addHorse.breed,
    weight: addHorse.weight,
    age: addHorse.age,
    intakeLevel: addHorse.intakeLevel,
    type: addHorse.type,
    comment: addHorse.comment,
    lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
    special: addHorse.special,
  };
  const ref: firebase.firestore.DocumentReference = firebase.firestore().collection('users').doc(uid).collection('clients')
    .doc(clientId)
    .collection('horses')
    .doc(horseId);
  ref.set(horse, { merge: true });
  horse.id = ref.id;
  horse.lastUpdated = new Date();
  const action: AddClientHorse = {
    type: ADD_CLIENT_HORSE,
    payload: {
      clientId,
      horse,
    },
  };
  dispatch(action);
  callback();
  return undefined;
}

interface Props {
  setOpen: Function
  isOpen: boolean
  clientId: string,
  horseId?: string,
  edit?: boolean,
}

const AddHorseSpecialInput = (addHorse: addHorseInterface, classes: any, dispatch: Function) => {
  switch (addHorse.type) {
  case (HorseType.Maintenace): {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="maintenance-native-simple">Maintenance Level</InputLabel>
        <Select
          native
          value={addHorse.special.maintenanceLevel}
          inputProps={{
            name: 'maintenanceLevel',
            id: 'maintenance-native-simple',
          }}
          onChange={(e) => horseSpecialHandler(addHorse.type, Number(e.target.value), dispatch)}
        >
          <option aria-label="None" value={-1} />
          <option value={1}>Low</option>
          <option value={2}>Average</option>
          <option value={3}>Heavy</option>
        </Select>
      </FormControl>
    );
  }
  case (HorseType.Stallion): {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="stallion-native-simple">Stallion Maintenance Level</InputLabel>
        <Select
          native
          value={addHorse.special.stallionMaintenanceLevel}
          inputProps={{
            name: 'stallion maintenance',
            id: 'stallion-native-simple',
          }}
          onChange={(e) => horseSpecialHandler(addHorse.type, Number(e.target.value), dispatch)}
        >
          <option aria-label="None" value={-1} />
          <option value={0}>Breeding</option>
          <option value={1}>Not Breeding</option>
        </Select>
      </FormControl>
    );
  }
  //
  case (HorseType.Growing): {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="growing-native-simple">Growing Workload</InputLabel>
        <Select
          key={addHorse.special.growingWorkLoad}
          native
          value={addHorse.special.growingWorkLoad}
          inputProps={{
            name: 'growing workload',
            id: 'growing-native-simple',
          }}
          onChange={(e) => horseSpecialHandler(addHorse.type, Number(e.target.value), dispatch)}
        >
          <option aria-label="None" value={-1} />
          <option value={0}>None</option>
          <option value={1}>Low</option>
          <option value={2}>Moderate</option>
          <option value={3}>Heavy</option>
          <option value={4}>Very Heavy</option>
        </Select>
      </FormControl>
    );
  }
  case (HorseType.Pregnant): {
    return (
      <FormControl className={classes.formControl}>
        <TextField
          autoFocus
          margin="dense"
          id="gestation"
          label="Month of Gestation"
          type="text"
          value={addHorse.special.gestationMonth}
          onChange={(e) => horseSpecialHandler(addHorse.type, Number(e.target.value), dispatch)}
        />
      </FormControl>
    );
  }
  case (HorseType.Lactating): {
    return (
      <FormControl className={classes.formControl}>
        <TextField
          autoFocus
          margin="dense"
          id="lactation"
          label="Month of Lactation"
          type="text"
          value={addHorse.special.lactationMonth}
          onChange={(e) => horseSpecialHandler(addHorse.type, Number(e.target.value), dispatch)}
        />
      </FormControl>
    );
  }
  case (HorseType.Exercise): {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="workload-native-simple">Workload</InputLabel>
        <Select
          native
          value={addHorse.special.workLoad}
          inputProps={{
            name: 'workload',
            id: 'workload-native-simple',
          }}
          onChange={(e) => horseSpecialHandler(addHorse.type, Number(e.target.value), dispatch)}
        >
          <option aria-label="None" value={-1} />
          <option value={1}>Low</option>
          <option value={2}>Moderate</option>
          <option value={3}>Heavy</option>
          <option value={4}>Very Heavy</option>
        </Select>
      </FormControl>
    );
  }
  default: {
    return undefined;
  }
  }
};

const displayError = (errors: AddHorseError, key: keyof AddHorseError) => {
  if (errors[key] !== undefined) {
    return (
      <FormHelperText error>{errors[key]}</FormHelperText>
    );
  }
};

const horseBreeds = [
  'American Quarter Horse',
  'Arabian',
  'Anglo-Arabian',
  'Appaloosa',
  'Australian Stock Horse',
  'Clydesdale',
  'Cob',
  'Coldblood trotter',
  'Connemara Pony',
  'Criollo',
  'Draught Horse',
  'Freisian',
  'Haflinger',
  'Hanovian',
  'Holsteiner',
  'Icelandic Horse',
  'Irish Draught',
  'Irish Sport Horse',
  'Other',
  'Percheron',
  'Thoroughbred',
  'Selle Français',
  'Shetland',
  'Shire',
  'Sport Horse',
  'Warmblood',
  'Welsh Pony',
];

const AddHorseDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const uid: string = useSelector((state: RootState) => state.user.id);
  const addHorse: addHorseInterface = useSelector((state: RootState) => state.addHorse);
  return (
    <Dialog open={props.isOpen} onClose={() => props.setOpen(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {props.edit ? 'Edit' : 'Add New'}
        &nbsp;
        Horse
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.edit ? 'Edit horse for the existing client.' : 'Add a new horse for the current client.' }
        </DialogContentText>
        <div className={classes.root}>
          <FormControl className={classes.formControl}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Horse Name"
              type="text"
              value={addHorse.name}
              onChange={(e) => horseNameHandler(e.target.value, dispatch)}
            />
            {displayError(addHorse.error, 'name')}
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-simple">Horse Breed</InputLabel>
            <Select
              native
              value={addHorse.breed}
              inputProps={{
                name: 'Breed',
                id: 'horseType-native-simple',
              }}
              onChange={(e) => horseBreedHandler((e.target.value as string), dispatch)}
            >
              <option aria-label="None" value="" />
              {horseBreeds.map((breed) => <option value={breed} key={breed}>{breed}</option>)}
            </Select>
            {displayError(addHorse.error, 'breed')}
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              autoFocus
              margin="dense"
              id="comment"
              label="Comment"
              type="text"
              value={addHorse.comment}
              onChange={(e) => horseCommentHandler(e.target.value, dispatch)}
            />
            {displayError(addHorse.error, 'comment')}
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-simple">Horse Type</InputLabel>
            <Select
              native
              value={addHorse.type}
              inputProps={{
                name: 'horseType',
                id: 'horseType-native-simple',
              }}
              onChange={(e) => horseTypeHandler(Number(e.target.value), dispatch)}
            >
              <option aria-label="None" value={-1} />
              <option value={HorseType.Maintenace}>Maintenance</option>
              <option value={HorseType.Stallion}>Stallion</option>
              <option value={HorseType.Growing}>Growing</option>
              <option value={HorseType.Pregnant}>Pregnant</option>
              <option value={HorseType.Lactating}>Lactating</option>
              <option value={HorseType.Exercise}>Exercise</option>
            </Select>
          </FormControl>
          {displayError(addHorse.error, 'type')}
          <FormControl className={classes.formControl}>
            <TextField
              autoFocus
              margin="dense"
              id="weight"
              label="Mature Adult Bodyweight (Kg)"
              type="text"
              value={addHorse.weight}
              onChange={(e) => horseWeightHandler(Number(e.target.value), dispatch)}
            />
            {displayError(addHorse.error, 'weight')}
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              autoFocus
              margin="dense"
              id="intakeLevel"
              label="Intake Level"
              type="text"
              disabled={!props.edit}
              value={addHorse.intakeLevel}
              onChange={(e) => horseIntakeLevelHandler(e, dispatch)}
            />
            {displayError(addHorse.error, 'intakeLevel')}
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              autoFocus
              margin="dense"
              id="age"
              label="Age (months)"
              type="text"
              value={addHorse.age}
              onChange={(e) => horseAgeHandler(Number(e.target.value), dispatch)}
            />
            {displayError(addHorse.error, 'age')}
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              autoFocus
              margin="dense"
              id="ageYears"
              label="Age (years)"
              type="text"
              value={Math.round(addHorse.age / 12) > 0 ? Math.round(addHorse.age / 12) : 0}
              onChange={(e) => horseAgeHandler(Number(e.target.value) * 12, dispatch)}
            />
          </FormControl>
          {AddHorseSpecialInput(addHorse, classes, dispatch)}
          {displayError(addHorse.error, 'special')}
        </div>
      </DialogContent>
      <DialogActions>

        {props.edit
          ? (
            <Button
              onClick={
                () => editHorse(addHorse, props.clientId, props.horseId as string, uid, dispatch, () => props.setOpen(false))
              }
              color="primary"
            >
              Confirm Changes
            </Button>
          )
          : (
            <Button
              onClick={
                () => addNewHorse(addHorse, props.clientId, uid, dispatch, () => props.setOpen(false))
              }
              color="primary"
            >
              Add New Horse
            </Button>
          )}
        <Button onClick={() => props.setOpen(false)} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddHorseDialog;
