import React from 'react';
import { withTranslation } from 'react-i18next';

import {
  Nutrients,
} from '../../redux/types';
import {
  table, theadStyle, tbodyp, reportSection, reportSectionTitle,
} from './horseReportStyle';

interface Props {
  contributors: Nutrients
  t: Function
}

const calculateProteinPercent = (netContributions: Nutrients) => {
  const otherMacrosSum = netContributions.crudeProtein.value + netContributions.fat.value + netContributions.starch.value + netContributions.sugar.value + netContributions.fibre.value;
  return Math.round((netContributions.crudeProtein.value / otherMacrosSum) * (100 / 1));
};

const HorseReportProteinInfo : React.FC<Props> = (props: Props) => {
  const proteinPercent = calculateProteinPercent(props.contributors);
  return (
    <div style={reportSection}>
      <div style={reportSectionTitle}>PROTEIN</div>
      <p style={tbodyp}>
        Protein makes up
        &nbsp;
        {proteinPercent}
        % of this diet. Protein serves a multitude of roles in the body.
        It is used to build and repair body
        tissues and is a major component of muscles, skin,
        tendons, blood vessels, organs, bone, hair and hoof.
        It also aids in
        regulating many of the bodies’ systems and functions.
      </p>
      <p style={tbodyp}>
        Unlike the other macronutrients, there are clear requirements
        for protein for each life stage, your horse’s
        requirements will be shown below.
      </p>
      <p style={tbodyp}>
        Protein is required by all ages of horses,
        but the amount and quality required depends on the horse’s age and
        physiological status. Young, growing horses
        and broodmares need higher levels of protein to aid growth and
        development.
      </p>
    </div>
  );
};

export const UntranslatedHorseReportProteinInfoSmall : React.FC<Props> = (props: Props) => {
  const proteinPercent = calculateProteinPercent(props.contributors);
  return (
    <p>
      <b>
        {props.t('macronutrients.crudeProtein.crudeProtein')}
        :
      </b>
      {' '}
      {props.t('macronutrients.crudeProtein.description', { proteinPercent })}
    </p>
  );
};

export const HorseReportProteinInfoSmall = withTranslation('common')(UntranslatedHorseReportProteinInfoSmall);

export default HorseReportProteinInfo;
