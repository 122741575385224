import React from 'react';
import '../styles/Flexbox.scss';
import '../styles/Navbar.scss';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core';

interface Props {
  name: string
  to: string
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
}));

const NavbarItem : React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const active = props.to === useLocation().pathname;
  return (
    <Link
      component={RouterLink}
      to={props.to}
      variant="button"
      className={classes.link}
      color={active ? 'primary' : 'inherit'}
    >
      {props.name}
    </Link>
  );
};

export default NavbarItem;
