/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useRef, useState } from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import {
  Horse, NutritionReport, Feed, Nutrients, SpecialNutritionalBounds, User, EditableReportSection, defaultNutrients,
} from '../../redux/types';
import { NutrientRequirementsOutput } from '../../helpers/nutrientRequirementWrapper';
import ReportBox, { NutrientRowItem } from './ReportBox';
import { HorseReportStarchInfoSmall } from '../viewReport/HorseReportStarchInfo';
import DietHorseInfo from './DietHorseInfo';
import DietHorseFeeds from './DietHorseFeeds';
import NutritionOverviewGraph from '../NutritionOverviewGraph';
import getDateAsString from '../../helpers/dateHelper';
import NutritionContributorsGraph from '../NutritionContributorsGraph';
import UntranslatedDietHorseTable from './DietHorseTable';
import NutritionMacrosBreakdownGraph2 from '../NutritionMacrosBreakdownGraph2';
import { HorseReportFibreInfo2 } from '../viewReport/HorseReportFibreInfo';
import { HorseReportProteinInfoSmall } from '../viewReport/HorseReportProteinInfo';
import HorseReportSugarInfo from '../viewReport/HorseReportSugarInfo';
import { HorseReportFatsInfoSmall } from '../viewReport/HorseReportFatsInfo';
import { HorseDietRatiosSmall } from '../viewReport/HorseDietRatios';
import HorseFooterBranding from '../viewReport/HorseFooterBranding';
import {
  DISCALIMER_TEXT, displayBrandInformation,
  EPL_GRAPH, MACRO_GRAPH, TRACE_GRAPH, VITAMINS_GRAPH,
} from './ReportConstants';
import EditableReportSectionComponent, { EditReportSectionDialog } from '../EditableReportSectionComponent';
import { commentsSectionTemplate } from '../../helpers/editableReportSectionsHelper';
import NutritionGenericReportGraph from '../NutritionGenericReportGraph';
import HorsePresenceComments from '../viewReport/HorsePresenceComments';
import DietHorseTable from './DietHorseTable';
import { checkReportBoxHeightTooLarge } from './Report';

interface Props {
  horse: Horse
  report: NutritionReport
  feeds: Map<string, Feed>
  combinedNetNutrients: Nutrients
  currentHorseRequirements: NutrientRequirementsOutput
  contributors: [string, Nutrients][] | undefined
  feedsAsDM: Nutrients
  percentages: Nutrients
  specialBounds: SpecialNutritionalBounds
  roughageAmount: number
  user: User
  brandingID: number
  totalFeedAmount: number
  totalFeedAmountAsDM: number
  t: Function
}

export const A4_PAGE: React.CSSProperties = {
  fontSize: '12px',
  minHeight: '1042px',
  height: '1042px',
  width: '695px',
  minWidth: '695px',
  maxWidth: '695px',
  flexDirection: 'column',
  display: 'flex',
  fontVariantLigatures: 'none',
  backgroundColor: 'white',
};

const LongReportUntranslated : React.FC<Props> = (props: Props) => {
  const commentsReportSection: EditableReportSection = props.report.editableReportSections.get(3) ? props.report.editableReportSections.get(3) as EditableReportSection : { content: `${commentsSectionTemplate}`, sectionID: 3 };
  const titleReportSection: EditableReportSection = props.report.editableReportSections.get(0) ? props.report.editableReportSections.get(0) as EditableReportSection : { content: `${commentsSectionTemplate}`, sectionID: 0 };
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [editDialogContent, setEditDialogContent] = useState('');
  const [editDialogSID, setEditDialogSID] = useState(-1);
  const brand = displayBrandInformation(props.brandingID);
  const p3Ref = useRef<any>(null);
  const [p3TooLarge, setP3TooLarge] = React.useState(false);
  useEffect(() => {
    if (p3Ref.current) {
      setP3TooLarge(checkReportBoxHeightTooLarge(p3Ref.current));
    }
  }, [p3Ref.current]);
  return (
    <div>
      <EditReportSectionDialog
        key={editDialogSID}
        isOpen={editDialogIsOpen}
        setOpen={setEditDialogIsOpen}
        editorContent={editDialogContent}
        editableSectionID={editDialogSID}
      />
      <div id="page-1" style={A4_PAGE as React.CSSProperties}>
        <img src={brand.headerImage} alt="branding" style={{ height: '143px', width: '650px', margin: '20px' }} />
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <div style={{ fontSize: '24px', fontWeight: 'bolder' }}>{props.report.title}</div>
          <div style={{ fontSize: '20px' }}>{getDateAsString(new Date())}</div>
        </div>
        <div style={{ display: 'flex', marginTop: 'auto', marginBottom: 'auto' }}>
          <ReportBox title={props.t('horse.profile')} fabIcon="info" fabColor="#B2B3B6" child={<DietHorseInfo horse={props.horse} />} />
          <ReportBox
            title={props.t('horse.feedIntake')}
            style={{
              marginTop: '0', marginBottom: '0', marginRight: '0', marginLeft: '2em',
            }}
            fabIcon="feed"
            fabColor="#B2B3B6"
            child={<DietHorseFeeds feeds={props.feeds} report={props.report} />}
          />
        </div>
        <ReportBox
          title="&nbsp;"
          fabIcon="pen"
          fabColor="#B2B3B6"
          child={(
            <>
              <EditableReportSectionComponent
                isNewReport
                sectionTitle=""
                editableReportSection={titleReportSection}
                setEditDiaogOpen={() => {
                  setEditDialogContent(titleReportSection.content);
                  setEditDialogSID(titleReportSection.sectionID);
                  setEditDialogIsOpen(true);
                }}
              />
            </>
          )}
        />
      </div>
      <div id="page-2" style={A4_PAGE as React.CSSProperties}>

        {props.contributors ? (
          <ReportBox
            noMargin
            title={props.t('report.feedContributors')}
            fabIcon="chart"
            fabColor="#B2B3B6"
            child={(
              <div style={{
                margin: 'auto',
                width: '80%',
              }}
              >
                <NutritionContributorsGraph
                  height={250}
                  contributors={props.contributors}
                />
              </div>
            )}
          />
        )
          : ''}
        <ReportBox
          noMargin
          title={props.t('report.tableOfNutrients')}
          fabIcon="scales"
          fabColor="#B2B3B6"
          child={(
            <DietHorseTable
              combinedNetNutrients={props.combinedNetNutrients}
              currentHorseRequirements={props.currentHorseRequirements}
              specialBounds={props.specialBounds}
            />
          )}
        />
      </div>
      <div id="page-3" style={A4_PAGE as React.CSSProperties} ref={p3Ref}>
        <ReportBox
          noMargin
          title=""
          fabIcon=""
          fabColor=""
          pageTooLarge={p3TooLarge}
          child={(
            <>
              <NutritionMacrosBreakdownGraph2
                contributors={props.combinedNetNutrients}
              />
              <div style={{ fontSize: '18px', fontWeight: 'bolder', marginLeft: '71px' }}>{props.t('report.macroNutrients')}</div>
              <NutrientRowItem
                title=""
                fabIcon="pastures"
                fabColor="#88e78d"
                child={(
                  <HorseReportFibreInfo2
                    horse={props.horse}
                    roughageAmount={props.roughageAmount}
                  />
                )}
              />
              <NutrientRowItem
                title=""
                fabIcon="grain"
                fabColor="#d8d57d"
                child={(
                  <HorseReportStarchInfoSmall
                    horse={props.horse}
                    contributors={props.combinedNetNutrients}
                  />
                )}
              />
              <NutrientRowItem
                title=""
                fabIcon="logistics"
                fabColor="#b091c7"
                child={
                  <HorseReportProteinInfoSmall contributors={props.combinedNetNutrients} />
                }
              />
              <NutrientRowItem
                title=""
                fabIcon="honey"
                fabColor="#76bdbd"
                child={
                  <HorseReportSugarInfo small contributors={props.combinedNetNutrients} />
                }
              />
              <NutrientRowItem
                title=""
                fabIcon="sunflowerb"
                fabColor="#ecbdec"
                child={
                  <HorseReportFatsInfoSmall contributors={props.combinedNetNutrients} />
                }
              />
            </>
          )}
        />
      </div>
      <div id="page-4" style={A4_PAGE as React.CSSProperties}>
        <ReportBox
          title={props.t('report.dietRatios')}
          fabIcon=""
          fabColor=""
          child={(
            <>
              <HorseDietRatiosSmall
                feedsAsDM={props.feedsAsDM}
                totalFeedAmountAsDM={props.totalFeedAmountAsDM}
                totalFeedAmount={props.totalFeedAmount}
                combinedNetNutrients={props.combinedNetNutrients}
              />
            </>
          )}
        />
        <ReportBox
          title={props.t('report.EPL')}
          fabIcon=""
          fabColor=""
          child={(
            <>
              <NutritionGenericReportGraph
                percentages={props.percentages}
                specialBounds={props.specialBounds}
                toInclude={EPL_GRAPH}
                height={175}
              />
            </>
          )}
        />

      </div>
      <div id="page-5" style={A4_PAGE as React.CSSProperties}>
        <ReportBox
          title={props.t('report.macroMinerals')}
          fabIcon=""
          fabColor=""
          child={(
            <>
              <NutritionGenericReportGraph
                percentages={props.percentages}
                specialBounds={props.specialBounds}
                toInclude={MACRO_GRAPH}
              />
            </>
          )}
        />
      </div>
      <div id="page-6" style={A4_PAGE as React.CSSProperties}>
        <ReportBox
          title={props.t('report.traceMinerals')}
          fabIcon=""
          fabColor=""
          child={(
            <>
              <NutritionGenericReportGraph
                percentages={props.percentages}
                specialBounds={props.specialBounds}
                toInclude={TRACE_GRAPH}
              />
            </>
          )}
        />
      </div>
      <div id="page-7" style={A4_PAGE as React.CSSProperties}>
        <ReportBox
          title={props.t('report.vitamins')}
          fabIcon=""
          fabColor=""
          child={(
            <>
              <NutritionGenericReportGraph
                percentages={props.percentages}
                specialBounds={props.specialBounds}
                toInclude={VITAMINS_GRAPH}
                height={175}
              />
            </>
          )}
        />
        <ReportBox
          title={props.t('report.additionalVitamins')}
          fabIcon=""
          fabColor=""
          child={(
            <>
              <HorsePresenceComments
                combinedNetNutrients={props.combinedNetNutrients}
                nutrients={['vitK', 'vitC', 'biotin']}
              />
            </>
          )}
        />
      </div>
      <div id="page-8" style={A4_PAGE as React.CSSProperties}>
        <ReportBox
          noMargin
          title={props.t('report.nutrientOverview')}
          fabIcon="report"
          fabColor="#B2B3B6"
          child={(
            <div style={{ margin: 'auto', width: '80%' }}>
              <NutritionOverviewGraph
                height={200}
                percentages={props.percentages}
                specialBounds={props.specialBounds}
              />
            </div>
          )}
        />
        <ReportBox
          title={props.t('report.reportHighlights')}
          fabIcon="idea"
          fabColor="#B2B3B6"
          child={(
            <>
              <EditableReportSectionComponent
                isNewReport
                sectionTitle="Consultant’s Comments"
                editableReportSection={commentsReportSection}
                setEditDiaogOpen={() => {
                  setEditDialogContent(commentsReportSection.content);
                  setEditDialogSID(commentsReportSection.sectionID);
                  setEditDialogIsOpen(true);
                }}
              />
            </>
          )}
        />
        <p style={{ fontSize: '10px' }}>
          <b>
            {props.t('report.disclaimer')}
            :
            {' '}
          </b>
          {props.t('report.disclaimerMessage')}
        </p>
        <div style={{ marginTop: 'auto' }}>
          <HorseFooterBranding
            user={props.user}
            brandingInfo={{
              name: brand.name,
              location: brand.address,
              socialMedia: brand.socialMedia,
              logoBottom: brand.logo,
              footerImage: brand.footerImage,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const LongReport = withTranslation('common')(LongReportUntranslated);

export default LongReport;
