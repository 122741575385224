/* eslint-disable max-len */
import {
  NutritionReport, CurrentNutritionReportAction, SET_CURRENT_NUTRITION_REPORT, SET_CURRENT_NUTRITION_REPORT_VALUE, ADD_FEED_TO_CURRENT_NUTRITION_REPORT, SET_CURRENT_NUTRITION_REPORT_AMOUNT, REMOVE_FEED_FROM_CURRENT_NUTRITION_REPORT, RESET_CURRENT_NUTRITION_REPORT, SET_EDITABLE_REPORT_SECTION,
} from './types';

interface CurrentNutritionReportState extends NutritionReport {
  hasChanged: boolean
}

const defaultState: CurrentNutritionReportState = {
  horseId: '',
  ownerId: '',
  isPublic: false,
  feedAmounts: new Map(), // FeedId -> Amount in KG
  title: '',
  note: '',
  lastUpdated: new Date(),
  creationDate: new Date(),
  hasChanged: false,
  editableReportSections: new Map(),
};

const currentReportNutritionReducer = (state: CurrentNutritionReportState = defaultState, action: CurrentNutritionReportAction) => {
  switch (action.type) {
  case (SET_CURRENT_NUTRITION_REPORT): {
    return { ...action.payload, hasChanged: false };
  }
  case (SET_CURRENT_NUTRITION_REPORT_VALUE): {
    const newState = { ...state } as any;
    // eslint-disable-next-line prefer-destructuring
    newState[action.payload[0]] = action.payload[1];
    newState.hasChanged = true;
    return (newState as CurrentNutritionReportState);
  }
  case (ADD_FEED_TO_CURRENT_NUTRITION_REPORT): {
    const newState = { ...state } as CurrentNutritionReportState;
    if (!newState.feedAmounts.get(action.payload[0])) newState.feedAmounts.set(action.payload[0], [action.payload[1], 0]);
    return { ...newState };
  }
  case (SET_CURRENT_NUTRITION_REPORT_AMOUNT): {
    // set feed amount
    const newState = { ...state } as CurrentNutritionReportState;
    newState.feedAmounts.set(action.payload[0], [action.payload[1], action.payload[2]]);
    return { ...newState };
  }
  case (REMOVE_FEED_FROM_CURRENT_NUTRITION_REPORT): {
    const newState = { ...state } as CurrentNutritionReportState;
    newState.feedAmounts.delete(action.payload[0]);
    return { ...newState };
  }
  case (RESET_CURRENT_NUTRITION_REPORT): {
    return { ...defaultState, feedAmounts: new Map() };
  }
  case (SET_EDITABLE_REPORT_SECTION): {
    const newState = { ...state } as CurrentNutritionReportState;
    newState.editableReportSections.set(action.payload.sectionID, action.payload);
    return { ...newState };
  }
  default: {
    return { ...state };
  }
  }
};

export default currentReportNutritionReducer;
