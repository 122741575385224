import React from 'react';
import { withTranslation } from 'react-i18next';

import {
  Horse,
} from '../../redux/types';
import {
  tbodyp, reportSectionTitle, reportSection,
} from './horseReportStyle';

interface Props {
  roughageAmount: number
  horse: Horse
  t: Function
}

const calculateRoughageEvaluation = (roughageAmount: number, horse: Horse, t: Function) => {
  let roughageValue: number = (roughageAmount / (horse.weight)) * (100 / 1);
  roughageValue = Math.round(roughageValue * 1e2) / 1e2;
  const roughageValueString = t('macronutrients.fibre.base', { roughageValue });
  if (roughageValue >= 1.5) {
    return `${roughageValueString} ${t('macronutrients.fibre.acceptable')}`;
  } if (roughageValue >= 1) {
    return `${roughageValueString} ${t('macronutrients.fibre.low')}`;
  }
  return `${roughageValueString} ${t('macronutrients.fibre.tooLow')}`;
};

const HorseReportFibreInfo : React.FC<Props> = (props: Props) => (
  <div style={reportSection}>
    <div style={reportSectionTitle}>FIBRE</div>
    <p style={tbodyp}>
      Fibre is a structural carbohydrate which is primarily digested by the billions of microbes living in the hindgut. Most of
      your horse’s fibre requirements will by met by forage (grass, hay, haylage, chaff, etc). Connolly’s Red Mills feeds also
      contain ‘super fibres’, highly digestible fibre sources that can be efficiently broken down to provide the horse with
      slow release energy.
    </p>
    <p style={tbodyp}>
      In addition to providing the horse with calories, fibre is required to achieve gut fill which reduces the risk of digestive
      disturbances and to hold water in the gut which serves as a reservoir for the horse.
    </p>
    <p style={tbodyp}>
      Horses are required to eat at least 1% of their bodyweight in roughage  (dry matter) per day to maintain gut function. The
      minimum recommended intake of roughage across all horse class is 1.5% of bodyweight in fibre per day.
    </p>
    <p style={tbodyp}>
      {calculateRoughageEvaluation(props.roughageAmount, props.horse, props.t)}
    </p>
  </div>
);

export const UntranslatedHorseReportFibreInfo2 : React.FC<Props> = (props: Props) => (
  <p>
    <b>
      {props.t('macronutrients.fibre.fibre')}
      :
    </b>
    {' '}
    {props.t('macronutrients.fibre.short')}
    {calculateRoughageEvaluation(props.roughageAmount, props.horse, props.t)}
  </p>
);

export const HorseReportFibreInfo2 = withTranslation('common')(UntranslatedHorseReportFibreInfo2);

export default HorseReportFibreInfo;
