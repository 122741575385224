import React from 'react';
import { withTranslation } from 'react-i18next';

import {
  Nutrients,
} from '../../redux/types';
import {
  table, theadStyle, tbodyp, reportSectionTitle, reportSection,
} from './horseReportStyle';

interface Props {
  contributors: Nutrients
  t: Function
}

const calculateFatsPercent = (netContributions: Nutrients) => {
  const otherMacrosSum = netContributions.crudeProtein.value + netContributions.fat.value + netContributions.starch.value + netContributions.sugar.value + netContributions.fibre.value;
  return Math.round((netContributions.fat.value / otherMacrosSum) * (100 / 1));
};

const HorseReportFatsInfo : React.FC<Props> = (props: Props) => {
  const fatsPercent = calculateFatsPercent(props.contributors);
  return (
    <div style={reportSection}>
      <div style={reportSectionTitle}>FATS AND OILS</div>
      <p style={tbodyp}>
        Fats and oils account for
        &nbsp;
        {fatsPercent}
        % of this diet. Increasing the level of fat and oil in the diet will increase the calorie density
        per gram feed, so adding oil is useful if your horse needs to gain weight. Fats and oils are also a useful addition to the
        diet as a ‘non-heating’ energy source and for healthy coat and glossy shine. Fats and oils are well tolerated in horses’
        diets and can account for 20% of their energy needs before causing any ill effects, though such high levels will likely
        have palatability issues and require additional antioxidant supplementation.
      </p>
      <p style={tbodyp}>
        Increasing the level of fats and oils in the diet should be done slowly as sudden increase may cause loose droppings
        due to a disturbance in hindgut bacteria.
      </p>
    </div>
  );
};

const UntranslatedHorseReportFatsInfoSmall : React.FC<Props> = (props: Props) => {
  const fatsPercent = calculateFatsPercent(props.contributors);
  return (
    <p>
      <b>
        {props.t('macronutrients.fat.fat')}
        :
      </b>
      {' '}
      {props.t('macronutrients.fat.description', { fatsPercent })}
    </p>
  );
};

export const HorseReportFatsInfoSmall = withTranslation('common')(UntranslatedHorseReportFatsInfoSmall);

export default HorseReportFatsInfo;
