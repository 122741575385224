import { Nutrients } from '../redux/types';

// CAV = cation anion value
const SODIUM_CAV = 23;
const POTASSIUM_CAV = 39.1;
const CALCIUM_CAV = 20.04;
const MAGNESIUM_CAV = 12.15;
const CHLORIDE_CAV = 35;
const SULPHUR_CAV = 16;
const PHOSPHORUS_CAV = 15.5;

export default function calculateDCABValue(nutrients: Nutrients, totalFeedAmount: number, forGraph?: boolean) {
  const mgSodium = 1000 * nutrients.sodium.value;
  const mgPotassium = 1000 * nutrients.potassium.value;
  const mgCalcium = 1000 * nutrients.calcium.value;
  const mgMagnesium = 1000 * nutrients.magnesium.value;
  const mgChloride = 1000 * nutrients.chloride.value;
  const mgSulphur = 1000 * nutrients.sulphur.value;
  const mgPhosphorus = 1000 * nutrients.phosphorous.value;

  const caSodium = mgSodium / SODIUM_CAV;
  const caPotassium = mgPotassium / POTASSIUM_CAV;
  const caCalcium = mgCalcium / CALCIUM_CAV;
  const caMagnesium = mgMagnesium / MAGNESIUM_CAV;
  const caChloride = mgChloride / CHLORIDE_CAV;
  const caSulphur = mgSulphur / SULPHUR_CAV;
  const caPhosphorus = mgPhosphorus / PHOSPHORUS_CAV;
  if (forGraph) {
    return [caSodium, caPotassium, caCalcium, caMagnesium, caChloride, caSulphur, caPhosphorus];
  }
  return ((caSodium + caPotassium + (0.38 * caCalcium)) + (0.3 * caMagnesium)
          - (caChloride + (0.6 * caSulphur) + (0.5 * caPhosphorus))) / totalFeedAmount;
}
