/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// material Ui Stuffs
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {
  Tabs, Tab,
} from '@material-ui/core';
import { RootState } from '../redux/root';
import { SET_HORSENAV_ACTIVE_TAB, SetHorseNavActiveTab } from '../redux/horseSubNavigation';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    flexGrow: 1,
    marginBottom: '2em',
  },
}));

export const setHorseSubNavigationActiveTab = (newValue: number, dispatch: Function) => {
  const action: SetHorseNavActiveTab = {
    type: SET_HORSENAV_ACTIVE_TAB,
    payload: Number(newValue),
  };
  dispatch(action);
};

interface Props {
  disableReport: boolean
}

const HorseSubNavigation : React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const activeTab: number = useSelector((state: RootState) => state.horseSubNavigation.activeTab);
  const dispatch = useDispatch();
  // Reset the navigation...
  useEffect(() => { setHorseSubNavigationActiveTab(0, dispatch); }, []);
  return (
    <div className={classes.root}>
      <Paper square className={classes.paper}>
        <Tabs
          onChange={(_, newValue) => setHorseSubNavigationActiveTab(newValue, dispatch)}
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          aria-label="disabled tabs example"
          variant="fullWidth"
        >
          <Tab value={0} label="Manage Feeds" />
          <Tab disabled={props.disableReport} value={1} label="View Report" />
          <Tab value={2} label="Manage Horse Details" />
        </Tabs>
      </Paper>
    </div>
  );
};

export default HorseSubNavigation;
