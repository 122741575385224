/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props {
  title: string,
  fabIcon: string,
  fabColor: string,
  child: any
  noMargin?: boolean
  style?: React.CSSProperties
  pageTooLarge?: boolean
}

const reportSection: React.CSSProperties = {
  border: '2px solid #d1d1d1',
  borderRadius: '10px',
  margin: 'auto',
  marginLeft: '2em',
  marginRight: 'auto',
  padding: '20px',
  wordBreak: 'break-all',
  marginTop: 'auto',
  width: '90%',
  fontSize: '13px',
};

const fabStyle: React.CSSProperties = {
  display: 'flex',
  position: 'relative',
  bottom: '40px',
  left: '-40px',
  borderRadius: '50%',
  backgroundColor: '#7fd985',
  width: '60px',
  height: '60px',
  zIndex: 1,
};

const fabIcon: React.CSSProperties = {
  width: '66%',
  height: '66%',
  margin: 'auto',
};

const ReportBox : React.FC<Props> = (props: Props) => {
  const heightFix = props.pageTooLarge ? { fontSize: '12px' } : {};
  return (
    <div style={{ ...reportSection, ...props.style, ...heightFix }}>
      <div style={{ display: props.fabIcon || props.title ? 'flex' : 'none', height: props.title ? '2.5em' : 0 }}>
        <div style={{ ...fabStyle, backgroundColor: props.fabColor, border: props.fabIcon ? fabStyle.border : 'none' }}>
          {props.fabIcon ? <img src={`/report/icons/${props.fabIcon}.png`} style={fabIcon} alt={props.fabIcon} /> : ''}
        </div>
        <div style={{
          fontWeight: 800, fontSize: '18px', textAlign: 'center', width: 'fit-content',
        }}
        >
          {props.title}
        </div>
      </div>
      <div style={props.noMargin ? {} : { marginTop: '2em' }}>{props.child}</div>
    </div>
  );
};

export const NutrientRowItem : React.FC<Props> = (props: Props) => (
  <div style={{ display: 'flex' }}>
    <div style={{
      display: 'flex',
      borderRadius: '50%',
      backgroundColor: props.fabColor,
      width: '4.5em',
      height: '4.5em',
      margin: 'auto',
      zIndex: 1,
    }}
    >
      <img
        src={`/report/icons/${props.fabIcon}.png`}
        style={{
          width: '66%',
          height: '66%',
          margin: 'auto',
        }}
        alt="chart"
      />
    </div>
    <div style={{
      flex: 1,
      marginLeft: '1.5em',
      wordBreak: 'break-word',
    } as React.CSSProperties}
    >
      {props.child}
    </div>
  </div>
);

export default ReportBox;
