import {
  User, UserAction, USER_LOGIN, USER_LOGOUT, UPDATE_USER_INFORMATION,
} from './types';

const defaultState: User = {
  id: '',
  loggedIn: false,
  name: '',
  email: '',
  phoneNumber: '',
  role: '',
  creationDate: new Date(),
  accountType: 0,
};

const userReducer = (state = defaultState, action: UserAction) => {
  switch (action.type) {
  case (USER_LOGIN): {
    return action.payload;
  }
  case (USER_LOGOUT): {
    return defaultState;
  }
  case (UPDATE_USER_INFORMATION): {
    return { ...state, ...action.payload };
  }
  default: return { ...state };
  }
};

export default userReducer;
