import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  Grid, CardContent, CardActions, Button, TextField,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../redux/root';
import { EditableAccountOptions, SET_EDITABLE_ACCOUNT_OPTION, UPDATE_USER_INFORMATION } from '../redux/types';
import ChangeAccountPassword from './ChangeAccountPassword';
import EquineAppConfig from '../EquineAppConfig';

const updateEditAccountState = (key: (keyof EditableAccountOptions), value: string, dispatch: Function) => {
  const action = {
    type: SET_EDITABLE_ACCOUNT_OPTION,
    payload: [key, value],
  };
  dispatch(action);
};

const updateUserAccountInformation = (uid: string, modifications: EditableAccountOptions, dispatch: Function, setRedirectTo: Function) => {
  const ref: firebase.firestore.DocumentReference = firebase.firestore().collection('users').doc(uid);
  ref.set(modifications, { merge: true });
  const action = {
    type: UPDATE_USER_INFORMATION,
    payload: modifications,
  };
  dispatch(action);
  setRedirectTo('/Home');
};

interface ModifyProfileProps {
  goBack: Function
}

const ModifyProfile: any = (props: ModifyProfileProps) => {
  const editAccountState = useSelector((state: RootState) => state.editAccount);
  const [redirectTo, setRedirectTo] = useState('');
  const uid = useSelector((state: RootState) => state.user.id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (redirectTo !== '') {
    navigate('/Login');
  }
  return (
    <Card style={{ width: '650px', marginTop: '2em' }}>
      <div style={{ textAlign: 'center' }}>
        <img style={{ marginTop: '5%' }} src={EquineAppConfig.branding.logo} alt="brand logo" />
      </div>
      <CardHeader
        title="Edit Profile"
        subheader="Change your profile information below."
      />
      <CardContent>
        <Grid container>
          <Grid item>
            <TextField
              autoFocus
              margin="dense"
              id="weight"
              label="Display Name"
              type="text"
              value={editAccountState.name}
              onChange={(e) => { updateEditAccountState('name', e.target.value, dispatch); }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <TextField
              autoFocus
              margin="dense"
              id="weight"
              label="Display Email"
              type="text"
              value={editAccountState.email}
              disabled
              onChange={(e) => { updateEditAccountState('email', e.target.value, dispatch); }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <TextField
              autoFocus
              margin="dense"
              id="weight"
              label="Display Number"
              type="text"
              value={editAccountState.phoneNumber}
              onChange={(e) => { updateEditAccountState('phoneNumber', e.target.value, dispatch); }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <TextField
              autoFocus
              margin="dense"
              id="weight"
              label="Display Role"
              type="text"
              value={editAccountState.role}
              onChange={(e) => { updateEditAccountState('role', e.target.value, dispatch); }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item style={{ marginTop: '1em' }}>
            <Button color="primary" onClick={() => updateUserAccountInformation(uid, editAccountState, dispatch, setRedirectTo)}>Save Display Information</Button>
          </Grid>
        </Grid>
        <hr />
        <Grid container>
          <Grid item>
            <ChangeAccountPassword />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button size="large" color="secondary" onClick={() => props.goBack()}>Go Back</Button>
      </CardActions>
    </Card>
  );
};

export default ModifyProfile;
