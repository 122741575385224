import {
  Clients, ClientsActions, UPDATE_CLIENTS, ADD_CLIENT, SET_HORSES,
  Client, ADD_CLIENT_HORSE, REMOVE_CLIENT_HORSE, Horse, UPDATE_CLIENT_NAME, REMOVE_CLIENT,
} from './types';

const defaultState: Clients = {
  clients: [],
  filter: '',
};

const clientsReducer = (state = defaultState, action: ClientsActions): Clients => {
  switch (action.type) {
  case (UPDATE_CLIENTS): {
    return ({ ...state, clients: action.payload });
  }
  case (ADD_CLIENT): {
    return ({ ...state, clients: [...state.clients, action.payload] });
  }
  case (SET_HORSES): {
    const clients: Client[] = [...state.clients];
    const client: Client | undefined = clients.find((c) => c.id === action.payload.clientId);
    if (typeof client !== 'undefined') client.horses = [...action.payload.horses];
    return ({ ...state, clients: [...clients] });
  }
  case (ADD_CLIENT_HORSE): {
    const clients: Client[] = [...state.clients];
    const client: Client = clients.find((c) => c.id === action.payload.clientId) as Client;
    client.horses = client.horses.filter((horse: Horse) => horse.id !== action.payload.horse.id);
    client.horses.push(action.payload.horse);
    return ({ ...state, clients });
  }
  case (REMOVE_CLIENT_HORSE): {
    const clients: Client[] = [...state.clients];
    const client: Client = clients.find((c) => c.id === action.payload.clientId) as Client;
    client.horses = client.horses.filter((horse: Horse) => horse.id !== action.payload.horseId);
    return ({ ...state, clients });
  }
  case (UPDATE_CLIENT_NAME): {
    const clients: Client[] = [...state.clients];
    const client: Client = clients.find((c) => c.id === action.payload.id) as Client;
    client.name = action.payload.name;
    client.lastUpdated = new Date();
    return ({ ...state, clients });
  }
  case (REMOVE_CLIENT): {
    const clients: Client[] = [...state.clients].filter((client) => client.id !== action.payload);
    return { ...state, clients };
  }
  default: {
    return ({ ...state });
  }
  }
};

export default clientsReducer;
