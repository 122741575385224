import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Nutrients, Feed } from '../redux/types';
import { RootState } from '../redux/root';

interface Props {
  contributors: [string, Nutrients][]
  height?: number
  t: Function
}

interface Dataset {
  label: string,
  data: number[]
  backgroundColor?: string[]
}

interface ChartData {
  datasets: Dataset[]
  labels: string[]
}

const NON_GRAPH_NUTRIENTS = ['fibre', 'sugar', 'fat', 'starch', 'sulphur', 'vitC', 'vitK', 'biotin'];
const GRAPH_NUTRIENTS = [
  'energy',
  'crudeProtein',
  'lysine',
  'calcium',
  'phosphorous',
  'sodium',
  'chloride',
  'potassium',
  'magnesium',
  'iron',
  'iodine',
  'cobalt',
  'copper',
  'manganese',
  'zinc',
  'selenium',
  'vitA',
  'vitD',
  'vitE',
];

const convertContributorsToDatasets = (contributors: [string, Nutrients][], feeds: Map<string, Feed>, t: Function): ChartData => {
  const labels: string[] = [];
  const datasets: Dataset[] = [];
  const datasetColors: string[] = ['rgb(47,141,250)', 'rgb(31,208,191)', 'rgb(235,100,139)', 'rgb(248,199,83)', 'rgb(169,55,144)', 'rgb(4,149,135)', 'rgb(85,0,140)'];
  contributors.forEach(([feedId, nutrients]) => {
    const dataset: any = {};
    dataset.label = feeds.get(feedId)!.name;
    dataset.data = [];
    GRAPH_NUTRIENTS.forEach((key) => (NON_GRAPH_NUTRIENTS.includes(key) ? undefined : dataset.data.push(nutrients[key as keyof Nutrients].value.toFixed(2))));
    dataset.backgroundColor = new Array(dataset.data.length).fill(datasetColors.shift());
    datasets.push(dataset);
  });
  if (contributors.length !== 0) {
    GRAPH_NUTRIENTS.forEach((key) => (NON_GRAPH_NUTRIENTS.includes(key)
      ? undefined
      : labels.push(t(`nutrients.${key}` as keyof Nutrients))));
  }
  return {
    datasets,
    labels,
  };
};

const UntranslatedNutritionContributorsGraph : React.FC<Props> = (props: Props) => {
  const feeds: Map<string, Feed> = useSelector((state: RootState) => state.feeds.feeds);
  if (props.contributors !== undefined) {
    const data = convertContributorsToDatasets(props.contributors, feeds, props.t);
    return (
      <HorizontalBar
        height={props.height ? props.height : 250}
        data={data}

        options={{
          maintainAspectRatio: true,
          legend: {
            display: true,
          },
          scales: {
            xAxes: [{
              stacked: true,
              ticks: {
                autoSkip: true,
                min: 0,
                max: 1000,
              },
            }],
            yAxes: [{
              stacked: true,
            }],
          },
        }}
      />
    );
  }
  return (<p>Loading...</p>);
};

const NutritionContributorsGraph = withTranslation('common')(UntranslatedNutritionContributorsGraph);

export default NutritionContributorsGraph;
