import {
  Horse, AddHorseActions, AddHorseError, ADD_HORSE_SET_AGE,
  ADD_HORSE_SET_BREED, ADD_HORSE_SET_COMMENT,
  ADD_HORSE_SET_INTAKE_LEVEL, ADD_HORSE_SET_NAME, ADD_HORSE_SET_SPECIAL,
  ADD_HORSE_SET_TYPE, ADD_HORSE_SET_WEIGHT, ADD_HORSE_SET_ERROR, HorseSpecial, ADD_HORSE_PROPS_FROM_HORSE, ADD_HORSE_RESET_TO_DEFAULT,
} from './types';

export interface addHorseInterface extends Horse {
  error: AddHorseError
  special: HorseSpecial
}

const defaultState: addHorseInterface = {
  id: '',
  name: '',
  breed: '',
  weight: 0,
  age: 0,
  intakeLevel: 2.50,
  type: -1,
  comment: '',
  lastUpdated: new Date(),
  creationDate: new Date(),
  error: {},
  special: {},
};

const addHorseReducer = (state = defaultState, action: AddHorseActions) => {
  switch (action.type) {
  case (ADD_HORSE_SET_AGE): {
    return { ...state, age: action.payload };
  }
  case (ADD_HORSE_SET_BREED): {
    return { ...state, breed: action.payload };
  }
  case (ADD_HORSE_SET_COMMENT): {
    return { ...state, comment: action.payload };
  }
  case (ADD_HORSE_SET_INTAKE_LEVEL): {
    return { ...state, intakeLevel: action.payload };
  }
  case (ADD_HORSE_SET_NAME): {
    return { ...state, name: action.payload };
  }
  case (ADD_HORSE_SET_TYPE): {
    return { ...state, type: action.payload };
  }
  case (ADD_HORSE_SET_WEIGHT): {
    return { ...state, weight: action.payload };
  }
  case (ADD_HORSE_SET_SPECIAL): {
    return { ...state, special: { ...action.payload } };
  }
  case (ADD_HORSE_SET_ERROR): {
    return { ...state, error: { ...state.error, ...action.payload } };
  }
  case (ADD_HORSE_PROPS_FROM_HORSE): {
    return { ...state, ...action.payload, error: {} };
  }
  case (ADD_HORSE_RESET_TO_DEFAULT): {
    return { ...defaultState };
  }
  default: return { ...state };
  }
};

export default addHorseReducer;
