import React from 'react';
import { HorizontalBar, defaults } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';

import { Nutrients, SpecialNutritionalBounds } from '../redux/types';
import '../helpers/chartJS.scss';

interface Props {
  percentages: Nutrients
  specialBounds: SpecialNutritionalBounds
  height?: number
  t: Function
}

interface Dataset {
  label: string,
  data: number[]
  backgroundColor?: string[]
}

interface ChartData {
  datasets: Dataset[]
  labels: string[]
}

const NON_GRAPH_NUTRIENTS = ['fibre', 'sugar', 'fat', 'starch', 'biotin', 'vitK', 'vitC', 'sulphur'];
const GRAPH_NUTRIENTS = [
  'energy',
  'crudeProtein',
  'lysine',
  'calcium',
  'phosphorous',
  'sodium',
  'chloride',
  'potassium',
  'magnesium',
  'iron',
  'iodine',
  'cobalt',
  'copper',
  'manganese',
  'zinc',
  'selenium',
  'vitA',
  'vitD',
  'vitE',
];

const convertNPToDatasets = (percentages: Nutrients, ranges: [Nutrients, Nutrients], t: Function): ChartData => {
  const low: number[] = [];
  const med: number[] = [];
  const high: number[] = [];
  const labels: string[] = [];
  GRAPH_NUTRIENTS.forEach((k) => {
    // eslint-disable-next-line no-param-reassign
    if (NON_GRAPH_NUTRIENTS.includes(k)) {
      return;
    }
    const key: keyof Nutrients = k as keyof Nutrients;
    labels.push(t(`nutrients.${key}`));
    if (percentages[key].value < ranges[0][key].value) {
      low.push(Math.round(percentages[key].value));
      med.push(0);
      high.push(0);
    } else if (percentages[key].value < ranges[1][key].value) {
      low.push(0);
      med.push(Math.round(percentages[key].value));
      high.push(0);
    } else {
      low.push(0);
      med.push(0);
      high.push(Math.round(percentages[key].value));
    }
  });
  const lowBackgroundColor = new Array(low.length).fill('rgba(255, 0, 0, 0.6)');
  const medBackgroundColor = new Array(low.length).fill('rgba(0, 128, 0, 0.6)');
  const highBackgroundColor = new Array(low.length).fill('rgba(242, 200, 15, 0.6)');
  return {
    labels,
    datasets: [
      { label: t('graph.belowRequired'), data: low, backgroundColor: lowBackgroundColor },
      { label: t('graph.requirementHit'), data: med, backgroundColor: medBackgroundColor },
      { label: t('graph.aboveRecommendedIntake'), data: high, backgroundColor: highBackgroundColor },
    ],
  };
};

export const calculateSpecialRanges = (specialBounds: SpecialNutritionalBounds) : [Nutrients, Nutrients] => {
  const low: Nutrients = {
    biotin: { value: 100, displayName: '', unit: '%' },
    calcium: { value: 100, displayName: '', unit: '%' },
    chloride: { value: 100, displayName: '', unit: '%' },
    cobalt: { value: 100, displayName: '', unit: '%' },
    copper: { value: 100, displayName: '', unit: '%' },
    crudeProtein: { value: 100, displayName: '', unit: '%' },
    energy: { value: 90, displayName: '', unit: '%' },
    fat: { value: 100, displayName: '', unit: '%' },
    fibre: { value: 100, displayName: '', unit: '%' },
    iodine: { value: 100, displayName: '', unit: '%' },
    iron: { value: 100, displayName: '', unit: '%' },
    lysine: { value: 100, displayName: '', unit: '%' },
    magnesium: { value: 100, displayName: '', unit: '%' },
    manganese: { value: 100, displayName: '', unit: '%' },
    phosphorous: { value: 100, displayName: '', unit: '%' },
    potassium: { value: 100, displayName: '', unit: '%' },
    selenium: { value: 100, displayName: '', unit: '%' },
    sodium: { value: 100, displayName: '', unit: '%' },
    starch: { value: 100, displayName: '', unit: '%' },
    sugar: { value: 100, displayName: '', unit: '%' },
    sulphur: { value: 100, displayName: '', unit: '%' },
    vitA: { value: 100, displayName: '', unit: '%' },
    vitC: { value: 100, displayName: '', unit: '%' },
    vitD: { value: 100, displayName: '', unit: '%' },
    vitE: { value: 100, displayName: '', unit: '%' },
    vitK: { value: 100, displayName: '', unit: '%' },
    zinc: { value: 100, displayName: '', unit: '%' },
  };
  const high: Nutrients = {
    biotin: { value: 6000, displayName: '', unit: '%' },
    calcium: { value: specialBounds.upper.calcium, displayName: '', unit: '%' },
    chloride: { value: specialBounds.upper.chloride, displayName: '', unit: '%' },
    cobalt: { value: 600, displayName: '', unit: '%' },
    copper: { value: specialBounds.upper.copper, displayName: '', unit: '%' },
    crudeProtein: { value: 200, displayName: '', unit: '%' },
    energy: { value: 110, displayName: '', unit: '%' },
    fat: { value: 600, displayName: '', unit: '%' },
    fibre: { value: 600, displayName: '', unit: '%' },
    iodine: { value: specialBounds.upper.iodine, displayName: '', unit: '%' },
    iron: { value: specialBounds.upper.iron, displayName: '', unit: '%' },
    lysine: { value: 200, displayName: '', unit: '%' },
    magnesium: { value: specialBounds.upper.magnesium, displayName: '', unit: '%' },
    manganese: { value: specialBounds.upper.manganese, displayName: '', unit: '%' },
    phosphorous: { value: specialBounds.upper.phosphorous, displayName: '', unit: '%' },
    potassium: { value: specialBounds.upper.potassium, displayName: '', unit: '%' },
    selenium: { value: specialBounds.upper.selenium, displayName: '', unit: '%' },
    sodium: { value: specialBounds.upper.sodium, displayName: '', unit: '%' },
    starch: { value: 600, displayName: '', unit: '%' },
    sugar: { value: 600, displayName: '', unit: '%' },
    sulphur: { value: 600, displayName: '', unit: '%' },
    vitA: { value: 600, displayName: '', unit: '%' },
    vitC: { value: 600, displayName: '', unit: '%' },
    vitD: { value: 600, displayName: '', unit: '%' },
    vitE: { value: 600, displayName: '', unit: '%' },
    vitK: { value: 600, displayName: '', unit: '%' },
    zinc: { value: specialBounds.upper.zinc, displayName: '', unit: '%' },
  };
  return [low, high];
};

const UntranslatedNutritionOverviewGraph : React.FC<Props> = (props: Props) => {
  defaults.global.defaultFontFamily = "'roboto', 'sans-serif";
  defaults.global.defaultFontSize = 13;
  defaults.global.defaultFontColor = 'rgba(0, 0, 0, 0.87)';
  if (props.percentages !== undefined) {
    const data = convertNPToDatasets(props.percentages, calculateSpecialRanges(props.specialBounds), props.t);
    return (
      <HorizontalBar
        height={props.height}
        data={data}
        options={{
          maintainAspectRatio: true,
          legend: {
            display: true,
          },
          scales: {
            xAxes: [{
              stacked: true,
              ticks: {
                autoSkip: true,
                min: 0,
                max: 1000,
              },
            }],
            yAxes: [{
              stacked: true,
            }],
          },
        }}
      />
    );
  }
  return (<p>Loading...</p>);
};

UntranslatedNutritionOverviewGraph.defaultProps = {
  height: 600,
};

const NutritionOverviewGraph = withTranslation('common')(UntranslatedNutritionOverviewGraph);

export default NutritionOverviewGraph;
