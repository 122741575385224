import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import firebase from 'firebase/app';
import { useNavigate } from 'react-router';
import {
  RemoveClientHorse, REMOVE_CLIENT_HORSE, DeleteNutritionReport, DELETE_NUTRITION_REPORT,
} from '../redux/types';
import { RootState } from '../redux/root';

interface Props {
  setOpen: Function
  isOpen: boolean
  clientId: string
  horseId: string
}

export const deleteHorse = (uid: string, clientId: string, horseId: string, dispatch: Function) => {
  const action: RemoveClientHorse = {
    type: REMOVE_CLIENT_HORSE,
    payload: {
      clientId,
      horseId,
    },
  };
  dispatch(action);
  const ref: firebase.firestore.DocumentReference = firebase.firestore().collection('users').doc(uid)
    .collection('clients')
    .doc(clientId)
    .collection('horses')
    .doc(horseId);
  ref.delete();
};

export const deleteReport = (horseId: string, dispatch: Function) => {
  const action: DeleteNutritionReport = {
    type: DELETE_NUTRITION_REPORT,
    payload: horseId,
  };
  dispatch(action);
  const ref: firebase.firestore.DocumentReference = firebase.firestore().collection('reports').doc(horseId);
  ref.delete();
};

const confirmDeletionHandler = (
  clientId: string,
  horseId: string,
  uid: string,
  dispatch: Function,
  navigate: any,
) => {
  deleteHorse(uid, clientId, horseId, dispatch);
  deleteReport(horseId, dispatch);
  navigate(`/Client/${clientId}`);
};

const DeleteHorseDialog: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const uid: string = useSelector((state: RootState) => state.user.id);
  const navigate = useNavigate();
  return (
    <Dialog open={props.isOpen} onClose={() => props.setOpen(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Delete Horse</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you wish to proceed and delete the selected horse?
          Deleting the horse will also delete it&apos;s associated report if it exists.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => confirmDeletionHandler(props.clientId, props.horseId, uid, dispatch, navigate)}>Confirm</Button>
        <Button color="primary" onClick={() => props.setOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteHorseDialog;
