/* eslint-disable max-len */
export const SET_HORSENAV_ACTIVE_TAB = 'SET_HORSENAV_ACTIVE_TAB';

export interface SetHorseNavActiveTab {
  type: typeof SET_HORSENAV_ACTIVE_TAB
  payload: number
}

interface horseSubNavigation {
  activeTab: number
}

type HorseSubNavigationAction = SetHorseNavActiveTab

const defaultState: horseSubNavigation = {
  activeTab: 0,
};

const horseSubNavigationReducer = (state: horseSubNavigation = defaultState, action: HorseSubNavigationAction) => {
  switch (action.type) {
  case (SET_HORSENAV_ACTIVE_TAB): {
    return { ...state, activeTab: action.payload };
  }
  default: {
    return { ...state };
  }
  }
};

export default horseSubNavigationReducer;
