/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// material Ui Stuffs
import { makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import { Button, Typography } from '@material-ui/core';
import { useParams } from 'react-router';
import {
  Client as IClient, User, SetCurrentNutritionReport, SET_CURRENT_NUTRITION_REPORT, NutritionReport, SetNutritionReport, SET_NUTRITION_REPORT, Feed, ADD_FEED, addFeed, Horse, ADD_CLIENT_HORSE, AddClientHorse, Nutrients, RemoveFeedFromCurrentNutritionReport, REMOVE_FEED_FROM_CURRENT_NUTRITION_REPORT, SpecialNutritionalBounds, ResetCurrentNutritionReport, RESET_CURRENT_NUTRITION_REPORT, EditableReportSection, EditableReportSectionID,
} from '../redux/types';
import { RootState } from '../redux/root';
import HorseSubNavigation from './HorseSubNavigation';
import HorseManageFeeds from './HorseManageFeeds';
import calculateRequirements, { NutrientRequirementsOutput } from '../helpers/nutrientRequirementWrapper';
import horsePercentageCalculator, { FeedWithAmount, calculateFeedsWithAmount, calculateTotalNutrientsAsDM } from '../helpers/horsePercentageManger';
import HorseManageInformation from './HorseManageInformation';
import HorseViewReport from './HorseViewReport';
import LoadingBackdrop from './LoadingBackdrop';

const useStyles = makeStyles((theme) => ({
  '@global': {
    i: {
      fontSize: '2em',
    },
  },
  button: {
    margin: theme.spacing(1),
    padding: '100%',
  },
  grid: {
    margin: 'auto',
    display: 'flex',
    alignContent: 'center',
    maxWidth: '50em',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface Props {
  clientId: string
  horseId: string
}

const GetTotalFeedAmountFromReport = (report: NutritionReport): number => Array.from(report.feedAmounts.entries()).map((value) => value[1][1]).reduce((a, b) => a + b, 0);

const setCurrentReport = (report: NutritionReport, dispatch: Function) => {
  const action: SetCurrentNutritionReport = {
    type: SET_CURRENT_NUTRITION_REPORT,
    payload: report,
  };
  dispatch(action);
};

// What happens when there is no report for this horse?
// We need to reset the state of the redux store to default
const resetCurrentReport = (dispatch: Function) => {
  const action: ResetCurrentNutritionReport = {
    type: RESET_CURRENT_NUTRITION_REPORT,
  };
  dispatch(action);
};

const getReportFromFirebase = (reportId: string, dispatch: Function) => {
  firebase.firestore().collection('reports').doc(reportId).get()
    .then((resp) => {
      if (resp.exists) {
        const data: NutritionReport = resp.data() as NutritionReport;
        data.horseId = reportId;
        const tempEditableReportSections = new Map<EditableReportSectionID, EditableReportSection>();
        Object.keys(data.editableReportSections).forEach((key) => {
          tempEditableReportSections.set((Number(key) as unknown as EditableReportSectionID), (data.editableReportSections as any)[key]);
        });
        data.editableReportSections = tempEditableReportSections;
        const m = new Map<string, [firebase.firestore.DocumentReference, number]>();
        Object.keys(data.feedAmounts).forEach((key) => {
          m.set(key, (data as any).feedAmounts[key]);
          ((data as any).feedAmounts[key][0] as firebase.firestore.DocumentReference).get().then((feedDoc) => {
            if (feedDoc.exists) {
              const feedData = feedDoc.data() as any;
              feedData.lastUpdated = feedData.lastUpdated.toDate();
              feedData.creationDate = feedData.creationDate.toDate();
              feedData.id = feedDoc.id;
              feedData.ref = feedDoc.ref;
              const newFeed: Feed = feedData as Feed;
              const action: addFeed = {
                type: ADD_FEED,
                payload: [feedDoc.id, newFeed],
              };
              dispatch(action);
            } else {
              m.delete(key);
            }
          }).catch((err) => console.error(err.message));
        });
        data.feedAmounts = m;
        const action: SetNutritionReport = {
          type: SET_NUTRITION_REPORT,
          payload: [reportId, data],
        };
        setCurrentReport(data, dispatch);
        dispatch(action);
      }
    });
};

const saveReportToFirebase = (report: NutritionReport, horseId: string, uid: string, dispatch: Function) => {
  const reportToSave = { ...report };
  const feedAmounts: any = {};
  report.feedAmounts.forEach((value, key) => {
    feedAmounts[key] = value;
  });
  reportToSave.feedAmounts = feedAmounts;
  const editableReportSections: any = {};
  report.editableReportSections.forEach((value, key) => {
    editableReportSections[key] = value;
  });
  reportToSave.editableReportSections = editableReportSections;

  reportToSave.ownerId = uid;
  const ref = firebase.firestore().collection('reports').doc(horseId);
  ref.set(reportToSave);
  const action: SetCurrentNutritionReport = {
    type: SET_CURRENT_NUTRITION_REPORT,
    payload: report,
  };
  dispatch(action);
};

const feedsExist = (currentReport: NutritionReport, feeds: Map<string, Feed>) => Array.from(currentReport.feedAmounts).findIndex(([key, value]) => feeds.get(key) === undefined) === -1;

interface WrapperProps {
  clientExistsFunction: Function
}

export const HorseViewWrapper: React.FC<WrapperProps> = (props: WrapperProps) => {
  const params = useParams();
  if (props.clientExistsFunction(params.clientId)) {
    return (
      <HorseViewContainer
        clientId={params.clientId as string}
        horseId={params.horseId as string}
      />
    );
  }
  return <LoadingBackdrop />;
};

const HorseViewContainer : React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const user: User = useSelector((state: RootState) => state.user);
  const reports: Map<string, NutritionReport> = useSelector((state: RootState) => state.nutritionReports.reports);
  const currentReport: NutritionReport = useSelector((state: RootState) => state.currentNutritionReport);
  const currentClient: IClient = useSelector((state: RootState) => state.clients.clients
    .filter((client) => client.id === props.clientId))[0] as IClient;
  let horse: Horse = currentClient.horses.find((h) => h.id === props.horseId) as Horse;
  if (horse === undefined) {
    firebase.firestore().collection('users').doc(user.id).collection('clients')
      .doc(currentClient.id)
      .collection('horses')
      .doc(props.horseId)
      .get()
      .then((horseDoc) => {
        if (horseDoc.exists) {
          horse = horseDoc.data() as Horse;
          horse.lastUpdated = (horse.lastUpdated as any).toDate();
          horse.creationDate = (horse.creationDate as any).toDate();
          horse.id = horseDoc.id;
          const action: AddClientHorse = {
            type: ADD_CLIENT_HORSE,
            payload: {
              clientId: props.clientId,
              horse,
            },
          };
          dispatch(action);
        }
      });
  }
  const feeds: Map<string, Feed> = useSelector((state:RootState) => state.feeds.feeds);
  useEffect(() => getReportFromFirebase(props.horseId, dispatch), [props.horseId]);
  useEffect(() => (reports.get(props.horseId) !== undefined ? setCurrentReport(reports.get(props.horseId) as NutritionReport, dispatch) : resetCurrentReport(dispatch)), [props.horseId]);
  let percentages: Nutrients | undefined; // Overview generic graph
  let specials: SpecialNutritionalBounds | undefined;
  let combinedNetNutrients: Nutrients | undefined;
  let roughageAmount: Number | undefined;
  let feedsWithAmounts: FeedWithAmount[] | undefined;
  let feedsAsDM: Nutrients | undefined;
  let totalFeedAmountAsDM: number = -1;
  const contributors: [string, Nutrients][] | undefined = []; // The contributors on a fair feed basis
  if (feedsExist(currentReport, feeds) && horse !== undefined) {
    const feedAmounts = Array.from(currentReport.feedAmounts.entries());
    [percentages as Nutrients, specials, combinedNetNutrients, roughageAmount] = horsePercentageCalculator(feeds, feedAmounts, calculateRequirements(horse), horse);
    feedsWithAmounts = calculateFeedsWithAmount(feeds, feedAmounts);
    if (feedsWithAmounts.length > 0) [feedsAsDM, totalFeedAmountAsDM] = calculateTotalNutrientsAsDM(feedsWithAmounts);
    feedAmounts.forEach((arry) => contributors!
      .push(
        [
          arry[0],
          (horsePercentageCalculator(feeds, [arry], calculateRequirements(horse), horse))[0],
        ],
      ));
  }
  const classes = useStyles();
  const uid: string = useSelector((state: RootState) => state.user.id);
  const menuState: number = useSelector((state:RootState) => state.horseSubNavigation.activeTab);
  return (
    <div className={classes.root}>
      <HorseSubNavigation disableReport={!specials} />
      <div>
        <Typography style={{ marginLeft: '2em', display: 'inline' }} color="textPrimary" variant="h4">{horse ? `${currentClient.name} / ${horse.name}` : ''}</Typography>
        <Button
          color="primary"
          variant="outlined"
          style={{
            width: '20em', display: 'inline', marginBottom: '1em', marginLeft: '2em',
          }}
          onClick={() => saveReportToFirebase(currentReport, props.horseId, uid, dispatch)}
        >
          Save To Cloud
        </Button>
      </div>
      {feedsExist(currentReport, feeds) && horse !== undefined
        ? (
          <>
            {menuState === 0 ? (
              <HorseManageFeeds
                contributors={contributors}
                combinedNetNutrients={combinedNetNutrients as Nutrients}
                percentages={percentages as Nutrients}
                specialBounds={specials as SpecialNutritionalBounds}
                totalFeedAmount={GetTotalFeedAmountFromReport(currentReport)}
                feedsWithAmounts={feedsWithAmounts}
                feedsAsDM={feedsAsDM}
                totalFeedAmountAsDM={totalFeedAmountAsDM}
              />
            ) : ''}
            {menuState === 1 ? (
              <HorseViewReport
                contributors={contributors}
                horse={horse}
                report={currentReport}
                feeds={feeds}
                combinedNetNutrients={combinedNetNutrients as Nutrients}
                feedsAsDM={feedsAsDM as Nutrients}
                totalFeedAmountAsDM={totalFeedAmountAsDM}
                currentHorseRequirements={calculateRequirements(horse)}
                specialBounds={specials as SpecialNutritionalBounds}
                percentages={percentages as Nutrients}
                roughageAmount={roughageAmount as number}
                user={user}
                totalFeedAmount={GetTotalFeedAmountFromReport(currentReport)}
              />
            ) : ''}
            {menuState === 2 ? <HorseManageInformation horse={horse} clientId={currentClient.id} /> : ''}
          </>
        )
        : ''}
    </div>
  );
};

export default HorseViewContainer;
