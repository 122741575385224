import { chartTextHelpers } from '../../helpers/chartTextHelpers';
import { genericChartToInclude } from '../NutritionGenericReportGraph';
import EquineAppConfig from '../../EquineAppConfig';

export const DISCALIMER_TEXT = `
The dietary recommendations above are based solely on the information supplied. Feed quantities may require adjustment
based on body condition, workload, the environment, and management conditions. If a horse is suffering from any clinical
condition then veterinary advice should be sought. Where necessary reference values for the typical nutritional values of forages
have been used. However, the nutrient and mineral content of forage is naturally very variable. The recommended values for
individual nutrients are based on a thorough review of scientific literature.`;

/* Branding Section */

export const displayBrandInformation = (brandID: number) => EquineAppConfig.branding.reports[brandID];

export const EPL_GRAPH: genericChartToInclude = [
  ['energy', chartTextHelpers('energy')],
  ['crudeProtein', chartTextHelpers('crudeProtein')],
  ['lysine', chartTextHelpers('lysine')],
];

export const MACRO_GRAPH: genericChartToInclude = [
  ['calcium', chartTextHelpers('calcium')],
  ['phosphorous', chartTextHelpers('phosphorous')],
  ['sodium', chartTextHelpers('sodium')],
  ['chloride', chartTextHelpers('chloride')],
  ['potassium', chartTextHelpers('potassium')],
  ['magnesium', chartTextHelpers('magnesium')],
];

export const TRACE_GRAPH: genericChartToInclude = [
  ['iron', chartTextHelpers('iron')],
  ['iodine', chartTextHelpers('iodine')],
  ['cobalt', chartTextHelpers('cobalt')],
  ['copper', chartTextHelpers('copper')],
  ['manganese', chartTextHelpers('manganese')],
  ['zinc', chartTextHelpers('zinc')],
  ['selenium', chartTextHelpers('selenium')],
];

export const VITAMINS_GRAPH: genericChartToInclude = [
  ['vitA', chartTextHelpers('vitA')],
  ['vitD', chartTextHelpers('vitD')],
  ['vitE', chartTextHelpers('vitE')],
];
