/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import { User } from '../../redux/types';
import {
  reportColumn, reportRow,
} from './horseReportStyle';
import EquineAppConfig from '../../EquineAppConfig';

export interface FooterReportBranding {
  name: string,
  location: string,
  socialMedia: string,
  logoBottom: string,
  footerImage: string,
}

interface Props {
  user: User
  brandingInfo: FooterReportBranding
}

const footerImageUnderlayStyle = {
  width: '695px',
  minWidth: '695px',
  maxWidth: '695px',
};

const footerOverlayText = {
  position: 'relative' as 'relative',
  left: '-410px',
  top: '60px',
  minWidth: '140px',
  fontSize: '10px',
};

const HorseFooterBranding : React.FC<Props> = (props: Props) => (
  <div style={reportRow}>
    <img style={footerImageUnderlayStyle} alt="redmills footer" src={props.brandingInfo.footerImage} />
    <div style={footerOverlayText}>
      <div>
        {props.brandingInfo.name}
      </div>
      <div>
        {props.brandingInfo.location}
      </div>
      <div>
        {props.user.name}
      </div>
      <div>
        {props.user.phoneNumber}
      </div>
      <div>
        {props.user.email}
      </div>
    </div>
  </div>
);

const HorseFooterBrandingOld : React.FC<Props> = (props: Props) => (
  <div style={reportRow}>
    <div style={{ ...reportColumn, fontSize: '12px' }}>
      <div>
        {props.brandingInfo.name}
      </div>
      <div>
        {props.brandingInfo.location}
      </div>
      <div>
        Consultant:
        &nbsp;
        {props.user.name}
      </div>
      <div>
        Consultant&apos;s Phone:
        &nbsp;
        {props.user.phoneNumber}
      </div>
      <div>
        Consultant&apos;s Email:
        &nbsp;
        {props.user.email}
      </div>
    </div>
    <div style={reportColumn}>
      <div style={reportRow}>
        {/* <img src='/eqa.png' style={{ height: '80px' }} /> */}
        <img src={props.brandingInfo.socialMedia} style={{ height: '80px' }} />
        <img src={props.brandingInfo.logoBottom} style={{ height: '80px' }} />
      </div>
    </div>
  </div>
);

export default HorseFooterBranding;
