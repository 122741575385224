/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import '../styles/Flexbox.scss';
import '../styles/Tables.scss';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { RootState } from '../redux/root';
import { Client, AddClient as AddClientAction, ADD_CLIENT } from '../redux/types';

async function addNewClient(clientName: string, uid: string, dispatch: Function, callback: Function) {
  const client: any = {
    name: clientName,
    creationDate: firebase.firestore.FieldValue.serverTimestamp(),
    lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
    horses: [],
  };
  const ref: firebase.firestore.DocumentReference = firebase.firestore()
    .collection('users')
    .doc(uid)
    .collection('clients')
    .doc();
  ref.set(client);
  client.id = ref.id;
  client.creationDate = new Date();
  client.lastUpdated = new Date();
  const action: AddClientAction = {
    type: ADD_CLIENT,
    payload: client as Client,
  };
  dispatch(action);
  callback();
}

interface Props {
  setOpen: Function
  isOpen: boolean
}

const AddClient: React.FC<Props> = (props: Props) => {
  const [name, setName] = useState('');
  const uid: string = useSelector((state: RootState) => state.user.id);
  const dispatch = useDispatch();
  return (
    <Dialog open={props.isOpen} onClose={() => props.setOpen(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add New Client</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Add a new client. The name you enter will be used to identify the horses for the reports you generate.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Client Name"
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setOpen(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={() => addNewClient(name, uid, dispatch, () => props.setOpen(false))} color="primary">
          Add New Client
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddClient;
