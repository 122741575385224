import * as CSS from 'csstype';
import React from 'react';

export const A4_PAGE: React.CSSProperties = {
  fontSize: '14px', minHeight: '1042px', height: '1042px', width: '695px', minWidth: '695px', maxWidth: '695px', flexDirection: 'column', display: 'flex',
};

export const reportSection: React.CSSProperties = {
  border: '3px solid black',
  borderRadius: '5px',
  margin: 'auto',
  padding: '20px',
  // backgroundColor: '#e7e6e6',
  width: '695px',
};

export const reportSectionTitle: React.CSSProperties = {
  fontWeight: 'bolder',
  fontSize: '16px',
  marginBottom: '15px',
};

export const reportColumn: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
};

export const reportRow: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export const table: React.CSSProperties = {
  marginBottom: '20px',
  width: '580px',
  maxWidth: '580px',
  borderStyle: 'solid',
  borderColor: 'grey',
  borderWidth: '1px',
  borderRadius: '5px',
  MozBorderRadius: '5px',
  WebkitBorderRadius: '5px',
  borderSpacing: '0',
  borderCollapse: 'separate',
};

export const lastTableStyle: React.CSSProperties = {
  width: '580px',
  maxWidth: '580px',
  marginBottom: '76px',
  borderStyle: 'solid',
  borderColor: 'grey',
  borderWidth: '1px',
  borderRadius: '5px',
  MozBorderRadius: '5px',
  WebkitBorderRadius: '5px',
  borderSpacing: '0',
  borderCollapse: 'separate',
};

export const theadStyle: React.CSSProperties = {
  color: 'white',
  fontWeight: 'bold',
};

export const poorTdStyling: React.CSSProperties = {
  padding: '2px',
  fontWeight: 'bold',
};

export const tdStyle: React.CSSProperties = {
  padding: '2px',
  fontWeight: 'bold',
  backgroundColor: '#e7e6e6',
};

export const tbodyp: React.CSSProperties = {
  margin: '10px',
};
