import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Nutrients, Feed, SpecialNutritionalBounds } from '../redux/types';
import { calculateSpecialRanges } from './NutritionOverviewGraph';
import { RootState } from '../redux/root';
import { nutrientRequirementState } from '../helpers/chartTextHelpers';

export type genericChartToInclude = [keyof Nutrients, (arg0: nutrientRequirementState, arg1: Function) => string][]

interface Props {
  percentages: Nutrients
  specialBounds: SpecialNutritionalBounds
  toInclude: genericChartToInclude
  height?: number
  t: Function
}

interface Dataset {
  label: string,
  data: number[]
  backgroundColor?: string[]
}

interface ChartData {
  datasets: Dataset[]
  labels: string[]
}

const NutritionGenericReportGraphText: React.FC<GraphTextProps> = (props: GraphTextProps) => (
  <p key={props.nutrientDisplayName} style={{ wordBreak: 'break-word', textAlign: 'justify' }}>
    <b>
      {props.nutrientDisplayName}
      :
      &nbsp;
    </b>
    {props.informationalText}
  </p>
);

const convertNPToDatasets = (percentages: Nutrients, toInclude: genericChartToInclude, ranges: [Nutrients, Nutrients], t: Function): [ChartData, any[]] => {
  const low: number[] = [];
  const med: number[] = [];
  const high: number[] = [];
  const labels: string[] = [];
  const informationalText: any[] = [];
  toInclude.forEach(([key, textHelper]) => {
    // eslint-disable-next-line no-param-reassign
    labels.push(t(`nutrients.${key}`));
    if (percentages[key].value < ranges[0][key].value) {
      informationalText.push(
        <NutritionGenericReportGraphText
          key={percentages[key].displayName}
          nutrientDisplayName={t(`nutrients.${key}`)}
          informationalText={textHelper(nutrientRequirementState.RED, t) as string}
        />,
      );
      low.push(Math.round(percentages[key].value));
      med.push(0);
      high.push(0);
    } else if (percentages[key].value < ranges[1][key].value) {
      informationalText.push(
        <NutritionGenericReportGraphText
          key={percentages[key].displayName}
          nutrientDisplayName={t(`nutrients.${key}`)}
          informationalText={textHelper(nutrientRequirementState.GREEN, t) as string}
        />,
      );
      low.push(0);
      med.push(Math.round(percentages[key].value));
      high.push(0);
    } else {
      informationalText.push(
        <NutritionGenericReportGraphText
          key={percentages[key].displayName}
          nutrientDisplayName={t(`nutrients.${key}`)}
          informationalText={textHelper(nutrientRequirementState.YELLOW, t) as string}
        />,
      );
      low.push(0);
      med.push(0);
      high.push(Math.round(percentages[key].value));
    }
  });
  const lowBackgroundColor = new Array(low.length).fill('rgba(255, 0, 0, 0.6)');
  const medBackgroundColor = new Array(low.length).fill('rgba(0, 128, 0, 0.6)');
  const highBackgroundColor = new Array(low.length).fill('rgba(242, 200, 15, 0.6)');
  return [{
    labels,
    datasets: [
      { label: t('graph.belowRequired'), data: low, backgroundColor: lowBackgroundColor },
      { label: t('graph.requirementHit'), data: med, backgroundColor: medBackgroundColor },
      { label: t('graph.aboveRecommendedIntake'), data: high, backgroundColor: highBackgroundColor },
    ],
  }, informationalText];
};

interface GraphTextProps {
  nutrientDisplayName: string,
  informationalText: string,
}

const UntranslatedNutritionGenericReportGraph : React.FC<Props> = (props: Props) => {
  if (props.percentages !== undefined) {
    const [data, informationalText] = convertNPToDatasets(props.percentages, props.toInclude, calculateSpecialRanges(props.specialBounds), props.t);
    return (
      <>
        <div>
          <HorizontalBar
            height={props.height ? props.height : 300}
            data={data}
            options={{
              maintainAspectRatio: false,
              legend: {
                display: true,
              },
              scales: {
                xAxes: [{
                  stacked: true,
                  ticks: {
                    autoSkip: true,
                    min: 0,
                    max: 1000,
                  },
                }],
                yAxes: [{
                  stacked: true,
                }],
              },
            }}
          />
        </div>
        {informationalText}
      </>
    );
  }
  return (<p>Loading...</p>);
};

const NutritionGenericReportGraph = withTranslation('common')(UntranslatedNutritionGenericReportGraph);

export default NutritionGenericReportGraph;
