import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Nutrients } from '../redux/types';
import 'chartjs-plugin-doughnutlabel';

interface Props {
  ratioValue: string
  contents: number[]
  backgroundColors: string[]
  labels?: string[]
}

interface Dataset {
  label: string,
  data: number[]
  backgroundColor?: string[]
}

interface ChartData {
  datasets: Dataset[]
  labels: string[]
}

const convertToDatasets = (contents: number[], backgroundColors: string[], labels: string[]) => {
  const dataset: Dataset = {
    label: '',
    data: contents,
    backgroundColor: backgroundColors,
  };
  return {
    datasets: [dataset],
    labels,
  };
};

const NutritionRatiosReportGraph : React.FC<Props> = (props: Props) => {
  if (props.contents !== undefined) {
    const data = convertToDatasets(props.contents, props.backgroundColors, props.labels ? props.labels : []);
    const options = {
      plugins: {
        labels: {
          // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
          render: 'image',
        },
        doughnutlabel: {
          labels: [
            {
              text: props.ratioValue,
              font: {
                size: '30',
              },
            },
          ],
        },
      },
      legend: {
        display: false,
      },
    };
    return (
      <Doughnut
        height={100}
        width={100}
        data={data}
        options={options}
      />
    );
  }
  return (<p>Loading...</p>);
};

export default NutritionRatiosReportGraph;
